import React, { useContext, useEffect, useMemo, useState } from "react";
import { Badge } from "components/Graphs/Badge";
import { SparkAreaChart } from "components/Graphs/SparkChart";
import { OrgContext } from "contexts/OrgContext";
import { useHistory } from "react-router-dom";
import Chip from "components/Environment/Create/chips";
import { classNames } from "components/utils";
import {
  BoltIcon,
  PlayCircleIcon,
  CubeIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/solid";
import { Deployment, DeploymentMetrics, DeploymentStatus } from "server";
import {
  formatDeploymentTimeSeries,
  getDeploymentInstanceTypeByType,
  mapStatusToLabel,
  mapStatusToVariant,
  mergeDeploymentSeries,
  placeholderData,
} from "../utils";
import { DeploymentsContext } from "contexts/DeploymentsContext";
import hfLogo from "assets/img/svg/hf-logo.svg";
import { GraphWrapper } from "../Settings/Metrics";
import { useQuery } from "@tanstack/react-query";
import agent from "server";
import nim from "../../../assets/img/png/nim.png";

const chartdata = [
  { month: "Jan 23", Performance: 0 },
  { month: "Feb 23", Performance: 400 },
  { month: "Mar 23", Performance: 600 },
  { month: "Apr 23", Performance: 800 },
  { month: "May 23", Performance: 1000 },
  { month: "Jun 23", Performance: 1500 },
  { month: "Jul 23", Performance: 1565 },
];

interface DeploymentCardProps {
  deployment: Deployment;
  isMock?: boolean;
}

export const DeploymentCard: React.FC<DeploymentCardProps> = ({
  deployment,
  isMock,
}) => {
  const orgContext = useContext(OrgContext);
  const deploymentsContext = useContext(DeploymentsContext);
  const history = useHistory();
  const [deploymentMetrics, setDeploymentMetrics] =
    useState<DeploymentMetrics>();
  const [loadingMetrics, setLoadingMetrics] = useState<boolean>(false);

  const getDeploymentMetrics = async () => {
    setLoadingMetrics(true);
    const res = await deploymentsContext.getDeploymentMetrics(
      orgContext.activeOrgId,
      deployment.deployment_id || ""
    );
    if (res) {
      setDeploymentMetrics(res);
    }
    setLoadingMetrics(false);
  };

  useEffect(() => {
    getDeploymentMetrics();
  }, []);

  const invocationsData = useMemo(
    () =>
      deploymentMetrics?.invocation_per_sec_time_series
        ? formatDeploymentTimeSeries(
            deploymentMetrics.invocation_per_sec_time_series,
            "Invocations"
          )
        : placeholderData.invocations,
    [deploymentMetrics?.invocation_per_sec_time_series]
  );

  const instancesData = useMemo(
    () =>
      deploymentMetrics?.instances_time_series
        ? formatDeploymentTimeSeries(
            deploymentMetrics.instances_time_series,
            "Instances"
          )
        : placeholderData.instances,
    [deploymentMetrics?.instances_time_series]
  );

  const gpuFromInstanceType = useMemo(() => {
    const result = getDeploymentInstanceTypeByType(
      deploymentsContext.instanceTypes || [],
      deployment?.instance_type || ""
    );
    if (result && result.supported_gpus) {
      return result.supported_gpus[0];
    }
    return null;
  }, [deployment, deploymentsContext.instanceTypes]);

  const getUsage = async (orgId: string) => {
    const res = await agent.Organizations.getUsage(orgId);
    if (!res.success || !res.data) {
      throw new Error("Failed to fetch usage data");
    }
    return res.data.Usage;
  };

  const { data: usageData } = useQuery({
    queryKey: ["usage", orgContext.activeOrgId],
    queryFn: () => getUsage(orgContext.activeOrgId),
    enabled: !!orgContext.activeOrgId,
  });

  const totalCost = useMemo(() => {
    const deploymentUsage = usageData?.find((usage) => {
      return usage.EnvID === deployment?.model_id;
    });
    if (deploymentUsage) {
      return parseFloat(deploymentUsage.Cost || "0.00");
    } else {
      return 0.0;
    }
  }, [usageData, deployment]);

  return (
    <div
      className={`
        bg-primaryBg 
        text-secondary
        rounded-md 
        border 
        ${
          isMock
            ? "border-zinc-800 opacity-40"
            : "border-zinc-800 hover:border-highlight cursor-pointer"
        }
        w-full 
        p-4 
        ${isMock ? "" : "transition-colors duration-200"}
      `}
      onClick={() => {
        if (!isMock) {
          history.push(
            `/org/${orgContext.activeOrgId}/deployments/${deployment.deployment_id}`
          );
        }
      }}
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-row">
          <div className="flex-1">
            <div className="flex items-center">
              <h2 className="text-lg font-semibold text-white mr-2">
                {deployment.name}
              </h2>
              <div className="flex flex-row text-xs text-secondary mt-[3px]">
                ID: {deployment.deployment_id}
              </div>
              <Badge
                variant={mapStatusToVariant(deployment?.status || 0)}
                className="ml-2 mt-[3px]"
              >
                {mapStatusToLabel(deployment?.status || 0)}
              </Badge>
            </div>
            <div className="flex flex-row mt-1 items-center">
              <div className="flex flex-col">
                <Chip
                  selected={true}
                  chipName={gpuFromInstanceType?.name || "???"}
                  manufacturerName={"NVIDIA"}
                  onClick={() => {}}
                  className={classNames("h-[50px]")}
                />
              </div>
              <div className="flex flex-col ml-2">
                <div className="flex flex-row items-center text-secondary">
                  <svg
                    width="20"
                    height="12"
                    viewBox="0 0 249 165"
                    className="mr-1"
                    fill="#76B900"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M25.43 70.902C25.43 70.902 47.934 37.699 92.867 34.264V22.218C43.098 26.215 0 68.367 0 68.367C0 68.367 24.41 138.932 92.867 145.393V132.589C42.63 126.269 25.43 70.902 25.43 70.902ZM92.867 107.125V118.851C54.899 112.082 44.36 72.614 44.36 72.614C44.36 72.614 62.59 52.419 92.867 49.144V62.011C92.844 62.011 92.828 62.004 92.809 62.004C76.918 60.097 64.504 74.942 64.504 74.942C64.504 74.942 71.462 99.933 92.867 107.125ZM92.867 0V22.218C94.328 22.106 95.789 22.011 97.258 21.961C153.84 20.054 190.707 68.367 190.707 68.367C190.707 68.367 148.364 119.855 104.25 119.855C100.207 119.855 96.422 119.48 92.867 118.85V132.589C95.907 132.975 99.059 133.202 102.348 133.202C143.399 133.202 173.086 112.237 201.832 87.424C206.598 91.241 226.11 100.527 230.121 104.592C202.789 127.475 139.09 145.921 102.977 145.921C99.496 145.921 96.153 145.71 92.867 145.393V164.699H248.899V0H92.867ZM92.867 49.144V34.264C94.313 34.163 95.77 34.086 97.258 34.039C137.946 32.761 164.64 69.004 164.64 69.004C164.64 69.004 135.808 109.047 104.894 109.047C100.445 109.047 96.457 108.332 92.867 107.125V62.011C108.707 63.925 111.894 70.922 121.417 86.797L142.597 68.938C142.597 68.938 127.136 48.661 101.073 48.661C98.24 48.66 95.529 48.859 92.867 49.144Z" />
                  </svg>
                  <div className="text-sm text-secondary">NVIDIA</div>
                </div>
                <div className="flex flex-row items-center text-secondary">
                  {!!gpuFromInstanceType && (
                    <>
                      <div className="text-sm text-slate-300 mr-1 font-bold">
                        {gpuFromInstanceType.count}x
                      </div>
                      <div className="text-sm text-secondary">
                        {gpuFromInstanceType.name}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col ml-4">
                <div className="flex flex-row items-center mt-1">
                  <BoltIcon className="w-4 h-4 mr-1" />
                  <div className="text-xs text-secondary">
                    Powered by Brev Endpoints
                  </div>
                </div>
                <div className="flex flex-row items-center mt-1">
                  <PlayCircleIcon className="w-4 h-4 mr-1" />
                  <div className="text-xs text-secondary">
                    $0.04/per invocation
                  </div>
                </div>
              </div>
              <div className="flex ml-4">
                <div className="flex flex-row">
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center">
                      <CubeIcon className={`w-5 h-5 mr-1`} />
                      <div className="flex flex-row text-xs text-secondary">
                        {deployment.artifact?.model?.model_name}{" "}
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <img
                        src={nim}
                        alt="Nim"
                        className="w-5 h-5 mr-1 rounded"
                      />
                      <div className="flex flex-row text-xs text-secondary">
                        Hugging Face Model
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex ml-4">
                <div className="flex flex-row">
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center text-secondary">
                      <Square3Stack3DIcon
                        className={`w-5 h-5 mr-1 text-slate-300`}
                      />
                      <div className="flex flex-row text-xs">
                        Min Instances: {deployment?.min_instances || 0}{" "}
                      </div>
                    </div>
                    <div className="flex flex-row items-center text-secondary">
                      <Square3Stack3DIcon
                        className={`w-5 h-5 mr-1 text-slate-300`}
                      />
                      <div className="flex flex-row text-xs">
                        Max Instances: {deployment?.max_instances || 0}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex ml-4">
                <div className="flex flex-row">
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center text-secondary">
                      <div className="flex flex-row text-xs mr-1">
                        Total Cost:
                      </div>
                      <div className="flex flex-row text-sm">
                        {totalCost.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row items-center text-secondary h-5 w-5"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-6">
          <div className="flex flex-col items-center">
            <GraphWrapper
              title="Invocation Activity"
              titleClassnames="text-xs mb-1 text-secondary"
              data={invocationsData}
              category={["Invocations"]}
              colors={["blue"]}
              valueFormatter={(num) => `${num}/sec`}
              isLoading={isMock ? false : loadingMetrics}
              isEmpty={
                isMock
                  ? false
                  : !deploymentMetrics?.invocation_per_sec_time_series
              }
              isSparkChart={true}
            />
          </div>
          <div className="flex flex-col items-center">
            <GraphWrapper
              title="Instances"
              titleClassnames="text-xs mb-1 text-secondary"
              data={instancesData}
              category={["Instances"]}
              colors={["emerald"]}
              valueFormatter={(num) => num.toString()}
              isLoading={isMock ? false : loadingMetrics}
              isEmpty={
                isMock
                  ? false
                  : !deploymentMetrics?.invocation_per_sec_time_series
              }
              isSparkChart={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
