import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useQueryParam from "useQueryParams";
import { ConnectCardWrapper } from "components/OrgPages/Billing/ConnectCard";
import BillingOverview from "./Overview";
import PaymentAndCredits from "./PaymentAndCredits";
import Payments from "./Payments";
import UsageSummary from "./UsageSummary";
import { useQuery } from "@tanstack/react-query";
import agent from "server";

interface LocationState {
  segment?: string;
}

const CreditsBillingPage: React.FC = () => {
  const { state } = useLocation<LocationState>();
  const orgContext = useContext(OrgContext);
  const billingRef = React.useRef<null | HTMLDivElement>(null);
  const [withCoupon, setWithCoupon] = useQueryParam("coupon", "");

  const getUsageOverview = async (orgId: string) => {
    const res = await agent.Organizations.getUsageOverview(orgId);
    if (!res.success || !res.data) {
      throw new Error("Failed to fetch usage overview");
    }
    return res.data.Usage || [];
  };

  const { data: usageOverview, isLoading: usageOverviewLoading } = useQuery({
    queryKey: ["usage-overview", orgContext.activeOrgId],
    queryFn: () => getUsageOverview(orgContext.activeOrgId),
    enabled: !!orgContext.activeOrgId,
  });

  useEffect(() => {
    document.title = "Organization Billing | Brev.dev";
    if (state?.segment === "billing") {
      billingRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  if (orgContext.activeOrg) {
    return (
      <>
        <div className="flex flex-col mt-8">
          <BillingOverview
            activeOrg={!!orgContext.activeOrg || false}
            usageOverview={usageOverview || []}
            usageOverviewLoading={usageOverviewLoading}
          />
        </div>
        <div className="flex flex-row mt-8">
          <div className="flex flex-col w-full">
            <PaymentAndCredits withCoupon={withCoupon} />
          </div>
        </div>
        <div className="mt-8">
          <Payments activeOrgId={orgContext.activeOrgId} />
        </div>
        <div className="mt-8">
          <UsageSummary activeOrgId={orgContext.activeOrgId} />
        </div>
      </>
    );
  }
  // when clicking the settings button, response time is so fast the seeing loading bars feels off
  return <div />;
};

export default CreditsBillingPage;
