import React, { useState, useContext } from "react";
import { MoonIcon, SunIcon } from "@heroicons/react/24/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";
import Button from "components/UI-lib/Button";
import { WorkspaceStatus, WorkspaceStartStatus } from "models/Workspace.model";
import {
  Capability,
  stopStartCapability,
  terminateCapability,
} from "contexts/OrgContext";
import Workspace from "entities/Workspace.entity";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { NotificationContext } from "contexts/NotificationContext";
import { OneClickDeployContext } from "contexts/OneClickDeployContext";
import { useHistory } from "react-router-dom";
import { StopModal } from "./DeprecatedSettings/InstanceSettings/StopModal";
import { FailedStartModal } from "./FailedStartModal";
import Modal from "components/Modals/Modal";

interface WorkspaceActionsProps {
  workspace: Workspace;
  capabilities: Capability[];
  deleteWorkspace: (workspaceId: string) => void;
  loadingDelete: boolean;
}

const WorkspaceActions: React.FC<WorkspaceActionsProps> = ({
  workspace,
  capabilities,
  deleteWorkspace,
  loadingDelete,
}) => {
  const [stopLoading, setStopLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showStartFailedModal, setShowStartFailedModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const wsContext = useContext(WorkspaceContext);
  const notificationContext = useContext(NotificationContext);
  const oneClickDeployContext = useContext(OneClickDeployContext);
  const history = useHistory();

  const stopWorkspace = async () => {
    setShowPopUp(false);
    setStopLoading(true);
    if (workspace) {
      if (oneClickDeployContext.workspace?.id === workspace.id) {
        oneClickDeployContext.reset();
      }
      const res = await wsContext.stopWorkspace(workspace.id);
      if (!res.success) {
        notificationContext.showNotification(
          "Unable to stop instance! Try again.",
          res.message,
          "error"
        );
      }
      setStopLoading(false);
    }
  };

  const startWorkspace = async () => {
    setStartLoading(true);
    if (workspace) {
      const res = await wsContext.startWorkspace(workspace.id);
      if (!res.success) {
        notificationContext.showNotification(
          "Unable to start instance! Try again.",
          res.message,
          "error"
        );
      }
      setStartLoading(false);
    }
  };

  const stopButtonDisabledMessage = () => {
    if (
      workspace?.workspaceGroupId &&
      !capabilities.includes(stopStartCapability)
    ) {
      return "This instance does not support stopping and starting";
    } else if (workspace?.status == WorkspaceStatus.Stopping) {
      return "workspace is stopping";
    } else {
      return "";
    }
  };

  return (
    <div className="flex flex-col">
      <StopModal
        show={showPopUp}
        setShow={setShowPopUp}
        onCancel={() => setShowPopUp(false)}
        onSuccess={stopWorkspace}
      />
      <FailedStartModal
        logFileContent={workspace?.lastStartStatusMessage || ""}
        show={showStartFailedModal}
        setShow={setShowStartFailedModal}
        onCancel={() => setShowStartFailedModal(false)}
        onSuccess={() => {
          setShowStartFailedModal(false);
        }}
      />
      <Modal
        setOpen={setShowConfirmationModal}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onSuccess={() => {
          if (workspace) {
            deleteWorkspace(workspace.id);
            setShowConfirmationModal(false);
          }
        }}
        title={`Are you sure you want to delete ${
          workspace ? workspace.name : ""
        }?`}
        body={
          <p className="text-sm text-gray-500 dark:text-secondary">
            Deleting an instance can't be undone.
          </p>
        }
        confirmLabel="Yes, Delete"
      />
      <div className="flex flex-row">
        {workspace.status !== WorkspaceStatus.Failure && (
          <div className="xl:mt-0">
            {workspace.status !== WorkspaceStatus.Stopped && (
              <span className="block">
                <Tooltip title={stopButtonDisabledMessage()} placement="top">
                  <span>
                    <Button
                      label="Stop"
                      type="secondary"
                      loading={stopLoading}
                      onClick={() => setShowPopUp(true)}
                      iconLeft={
                        <MoonIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-400 dark:text-secondary"
                          aria-hidden="true"
                        />
                      }
                      disabled={
                        !capabilities.includes(stopStartCapability) ||
                        workspace.status == WorkspaceStatus.Stopping
                      }
                    />
                  </span>
                </Tooltip>
              </span>
            )}
            {workspace.status == WorkspaceStatus.Stopped && (
              <span className="hidden sm:block">
                <Button
                  label="Start"
                  type="primary"
                  loading={
                    startLoading ||
                    workspace.lastStartStatus === WorkspaceStartStatus.Starting
                  }
                  onClick={startWorkspace}
                  iconLeft={
                    <SunIcon
                      className="-ml-1 mr-2 h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  }
                />
              </span>
            )}
          </div>
        )}
        {capabilities.includes(terminateCapability) && (
          <span className="ml-3">
            <Button
              label="Delete"
              type="danger"
              onClick={() => setShowConfirmationModal(true)}
              loading={loadingDelete}
            />
          </span>
        )}
      </div>
      {workspace.lastStartStatus === WorkspaceStartStatus.Failure && (
        <div
          className="flex flex-row mt-2 text-xs text-rose-600 cursor-pointer items-center hover:text-rose-400"
          onClick={() => setShowStartFailedModal(true)}
        >
          <ExclamationTriangleIcon className="h-4 w-4 mr-1" />
          <span>Previous Start Attempt Failed</span>
        </div>
      )}
    </div>
  );
};

export default WorkspaceActions;
