import { Button, InputField } from "components/UI-lib";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import agent from "server";

const SetResourceLimits: React.FC = () => {
  const [organizationId, setOrganizationId] = useState("");
  const [resourceLimit, setResourceLimit] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const { mutate: setResourceLimits, isLoading } = useMutation({
    mutationFn: () =>
      agent.Admin.setResourceLimits(
        organizationId,
        Number(resourceLimit) * 100
      ),
    onSuccess: (res) => {
      if (res.success) {
        setSuccess("Successfully seet resource limits to organization");
        setError("");
      } else {
        setError(res.message || "Failed to set resource limits");
        setSuccess("");
      }
    },
    onError: (err: any) => {
      setError(
        err.message || "An error occurred while setting resource limits"
      );
      setSuccess("");
    },
  });

  return (
    <div className="flex flex-col">
      <h2 className="text-xl font-bold text-white">
        Set Resource Limits for an Organization
      </h2>
      <p className="text-sm text-secondary mb-5">
        Set the maximum allowed spending limit (USD) per hour for this
        organization (default is 15.00 USD)
      </p>
      <div className="flex flex-col">
        <div className="flex flex-row gap-6">
          <InputField
            label="1. Enter a dollar amount"
            placeholder="0.00"
            value={resourceLimit}
            onChange={(e) => {
              // Only allow numbers and one decimal point
              const regex = /^\d*\.?\d*$/;
              if (regex.test(e) || e === "") {
                setResourceLimit(e);
              }
            }}
            errorMessage=""
          />
          <InputField
            label="2. Enter an organization id"
            placeholder=""
            value={organizationId}
            onChange={(e) => setOrganizationId(e)}
            errorMessage=""
          />
          <div className="flex flex-row items-end">
            <Button
              label="Set Resource Limit"
              className="h-10"
              onClick={() => setResourceLimits()}
              loading={isLoading}
              disabled={isLoading || !organizationId}
            />
          </div>
        </div>
        {error && <div className="mt-2 text-red-500 text-sm">{error}</div>}
        {success && (
          <div className="mt-2 text-highlight text-sm">{success}</div>
        )}
      </div>
    </div>
  );
};

export default SetResourceLimits;
