import Button from "components/UI-lib/Button";
import InputField from "components/UI-lib/InputField";
import { classNames } from "components/utils";
import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { LaunchablePort } from "server";

interface ExposePortsProps {
  ports: LaunchablePort[];
  setPorts: (ports: LaunchablePort[]) => void;
}

const ExposePorts: React.FC<ExposePortsProps> = (props) => {
  const [ports, setPorts] = useState(
    props.ports.length > 0 ? props.ports : [{ name: "", port: "" }]
  );

  const santizePorts = (ports: LaunchablePort[]) => {
    return ports.filter((port) => port.name || port.port);
  };

  useEffect(() => {
    props.setPorts(santizePorts(ports));
  }, [ports]);

  const handleNameChange = (index: number, value: string) => {
    const updatedPorts = [...ports];
    updatedPorts[index].name = value;
    setPorts(updatedPorts);
  };

  const handlePortChange = (index: number, value: string) => {
    const updatedPorts = [...ports];
    updatedPorts[index].port = value;
    setPorts(updatedPorts);
  };

  const handleAddMore = () => {
    setPorts([...ports, { name: "", port: "" }]);
  };

  const handleDelete = (index: number) => {
    const updatedPorts = [...ports];
    updatedPorts.splice(index, 1);
    setPorts(updatedPorts);
  };

  return (
    <>
      <div className="mb-5">
        <h1
          className="text-xl font-semibold text-gray-900 dark:text-white mb-1"
          style={{
            fontSize: "1.875rem",
          }}
        >
          Expose Ports
        </h1>
        <p className="text-md mt-2 text-gray-700 dark:text-secondary">
          Configure ports you want to make accessible
        </p>
        <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
        <div className="mt-5">
          <div className="flex flex-col max-w-[700px]">
            <table className="min-w-full border border-gray-300 dark:border-zinc-800">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-700 dark:text-secondary"
                  >
                    <div className="group inline-flex">Name</div>
                  </th>
                  <th
                    scope="col"
                    className="border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-700 dark:text-secondary"
                  >
                    <div className="group inline-flex">Port</div>
                  </th>
                  <th
                    scope="col"
                    className="border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-700 dark:text-secondary"
                  >
                    <div className="group inline-flex"></div>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-zinc-900">
                {ports.map((port, index, list) => (
                  <tr key={index}>
                    <td
                      className={classNames(
                        index !== list.length - 1
                          ? "border-b border-gray-200 dark:border-zinc-800"
                          : "",
                        "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
                        "px-3 py-4"
                      )}
                    >
                      <div className="w-15">
                        <InputField
                          label=""
                          value={port.name}
                          onChange={(value) => handleNameChange(index, value)}
                          errorMessage=""
                          placeholder="Inference Server"
                        />
                      </div>
                    </td>
                    <td
                      className={classNames(
                        index !== list.length - 1
                          ? "border-b border-gray-200 dark:border-zinc-800"
                          : "",
                        "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
                        "px-3 py-4"
                      )}
                    >
                      <div className="w-15">
                        <InputField
                          label=""
                          value={port.port}
                          onChange={(value) => handlePortChange(index, value)}
                          errorMessage=""
                          onKeyDown={(e) => {
                            if (
                              !/[0-9\b]/.test(e.key) &&
                              e.key !== "Backspace" &&
                              e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          placeholder="3000"
                        />
                      </div>
                    </td>
                    <td
                      className={classNames(
                        index !== list.length - 1
                          ? "border-b border-gray-200 dark:border-zinc-800"
                          : "",
                        "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
                        "px-3 py-4"
                      )}
                    >
                      {list.length > 1 && (
                        <div className="w-15">
                          <Button
                            type="danger"
                            label="Delete"
                            onClick={() => handleDelete(index)}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex flex-row">
              <Button
                type="secondary"
                label="Add or Remove Ports"
                onClick={handleAddMore}
                className="w-full justify-center"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExposePorts;
