import DevToggle from "components/DevToggle";
import FlatCard from "components/UI-lib/FlatCard";
import { OrgContext, IOrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import agent, { BillingProfile, Invoice } from "server";
import { useQuery } from "@tanstack/react-query";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from "components/Graphs/Table";
import { convertUnixEpochToDate } from "./utils";
import { classNames } from "components/utils";

interface PaymentsProps {
  activeOrgId: string;
}

const Payments: React.FC<PaymentsProps> = ({ activeOrgId }) => {
  const { data: invoices, isLoading: invoicesLoading } = useQuery({
    queryKey: ["invoices", activeOrgId],
    queryFn: async () => {
      const response = await agent.Organizations.getInvoices(activeOrgId);
      return response.data?.invoices ?? [];
    },
    enabled: !!activeOrgId,
  });

  return (
    <FlatCard className="w-full" noBorder={true}>
      <h1 className="text-xl font-medium dark:text-white mb-1">Payments</h1>
      <div>
        <p className="text-sm text-gray-700 dark:text-secondary">
          Review your payment history
        </p>
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      {invoicesLoading ? (
        <FlatCard isLoading={true}>
          <div className="flex w-full justify-center">
            <span className="text-sm text-gray-700 dark:text-secondary">
              Fetching invoices (this can take a moment)
            </span>
          </div>
        </FlatCard>
      ) : (
        <PreviousPaymentsTable invoices={invoices ?? []} />
      )}
    </FlatCard>
  );
};

interface PreviousPaymentsTableProps {
  invoices: Invoice[];
}

const PreviousPaymentsTable: React.FC<PreviousPaymentsTableProps> = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const filteredInvoices = useMemo(() => {
    return props.invoices.filter(
      (i) =>
        i.status !== "void" && i.amount_due !== undefined && i.amount_due > 0
    );
  }, [props.invoices]);

  const currentInvoices = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredInvoices.slice(startIndex, startIndex + itemsPerPage);
  }, [currentPage, filteredInvoices]);

  const totalPages = Math.ceil(filteredInvoices.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getStatusText = (invoice: Invoice): string => {
    if (invoice.refund !== undefined) {
      return "Refunded";
    }
    if (invoice.status === "paid") {
      return "Paid";
    }
    if (invoice.status === "open") {
      return "Failed";
    }
    return "";
  };

  if (currentInvoices.length === 0) {
    return (
      <FlatCard className="w-full justify-center">
        <div className="flex w-full justify-center">
          <span className="text-sm text-gray-700 dark:text-secondary">
            No Payment History Found
          </span>
        </div>
      </FlatCard>
    );
  }

  return (
    <>
      <TableRoot>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Date</TableHeaderCell>
              <TableHeaderCell>Amount</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>Invoice</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentInvoices.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary ">
                    {convertUnixEpochToDate(item.created || 0)}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {`$${((item.amount_due || 0) / 100).toFixed(2)}` || 0}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {getStatusText(item)}
                  </span>
                </TableCell>
                <TableCell>
                  {item.status === "paid" && (
                    <a href={item.invoice_url}>
                      <span className="text-highlight underline">View</span>
                    </a>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableRoot>
      <div className="flex justify-end mt-3 mr-5 items-center ">
        <div onClick={handlePreviousPage} className="mr-3 mt-[2px]">
          <FlatCard
            noBorder={true}
            className={classNames(
              currentPage === 1 ? "hidden" : "cursor-pointer",
              "border border-gray-500 dark:border-zinc-800",
              "w-full flex items-center",
              "px-2 py-2",
              "text-xs",
              "h-7"
            )}
          >
            <span>Back</span>
          </FlatCard>
        </div>
        <span className="">{` Page ${currentPage} of ${totalPages} `}</span>
        <div onClick={handleNextPage} className="ml-3 mt-[2px]">
          <FlatCard
            noBorder={true}
            className={classNames(
              currentPage === totalPages ? "hidden" : "cursor-pointer",
              "border border-gray-500 dark:border-zinc-800",
              " w-full flex items-center",
              "px-2 py-2",
              "text-xs",
              "h-7"
            )}
          >
            <span>Next</span>
          </FlatCard>
        </div>
      </div>
    </>
  );
};

export default Payments;
