import { Button, InputField } from "components/UI-lib";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import agent from "server";

const AddDgxc: React.FC = () => {
  const [organizationId, setOrganizationId] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const { mutate: addDgxc, isLoading } = useMutation({
    mutationFn: () => agent.Admin.addDgxcToOrganization(organizationId),
    onSuccess: (res) => {
      if (res.success) {
        setSuccess("Successfully added DGXC to organization");
        setError("");
      } else {
        setError(res.message || "Failed to add DGXC to organization");
        setSuccess("");
      }
    },
    onError: (err: any) => {
      setError(err.message || "An error occurred while adding DGXC");
      setSuccess("");
    },
  });

  return (
    <div className="flex flex-col">
      <h2 className="text-xl font-bold mb-4 text-white">
        Add DGXC to an Organization
      </h2>
      <div className="flex flex-col">
        <div className="flex flex-row gap-6">
          <InputField
            label="Enter the organization id"
            placeholder=""
            value={organizationId}
            onChange={(e) => {
              setOrganizationId(e);
            }}
            errorMessage=""
          />
          <div className="flex flex-row items-end">
            <Button
              label="Add DGXC"
              className="h-10"
              onClick={() => addDgxc()}
              loading={isLoading}
              disabled={isLoading || !organizationId}
            />
          </div>
        </div>
        {error && <div className="mt-2 text-red-500 text-sm">{error}</div>}
        {success && (
          <div className="mt-2 text-highlight text-sm">{success}</div>
        )}
      </div>
    </div>
  );
};

export default AddDgxc;
