/* eslint-disable react/no-unused-prop-types */
// they are being used idk they aren't beinf detected by eslint
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import {
  Capability,
  OrgContext,
  stopStartCapability,
  terminateCapability,
} from "contexts/OrgContext";
import { getWorkspaceStatusChip } from "components/Environment/List";
import {
  HealthStatus,
  IApplication,
  VerbBuildStatus,
} from "models/Workspace.model";
import {
  ChevronRightIcon,
  MoonIcon,
  SunIcon,
  BookOpenIcon,
} from "@heroicons/react/24/solid";
import {
  ClockIcon,
  CloudIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useParams, useHistory } from "react-router-dom";
import Workspace from "../../../entities/Workspace.entity";
import RetroPopUp from "components/Modals/RetroPopup";
import { NotificationContext } from "contexts/NotificationContext";
import Button from "components/UI-lib/Button";
import { useQuery } from "@tanstack/react-query";
import { useFeatureFlagEnabled } from "posthog-js/react";
import agent, { EnvEvent, Timeframe, WorkspaceGroup } from "server";
import CPUIcon from "assets/img/svg/cpuicon.svg?react";
import MapPin from "assets/img/svg/mappin.svg?react";
import CreditCardIcon from "assets/img/svg/creditcardicon.svg?react";
import DatabaseIcon from "assets/img/svg/databaseicon.svg?react";
import {
  areStringListsEqual,
  arrayToPathMap,
  classNames,
  fetchLegacyNotebookList,
  isCPU_techdebt,
  isCheckHealthSame,
  isSharedWorkspace,
  mapValuesToArray,
  parseStatusMessage,
  regionToReadable,
  timeout,
  yamlRawDump,
} from "components/utils";
import { WorkspaceStatus } from "models/Workspace.model";
import { WorkspaceStartStatus } from "models/Workspace.model";
import { UserContext } from "contexts/UserContext";

import { InstanceAttribute } from "./Tabs/Compute/InstanceChanger/InstanceAttribute";
import { Container, Tooltip } from "@mui/material";
import Modal from "components/Modals/Modal";
import {
  DEFAULT_VERB_CONFIG,
  WorkspaceCreateContext,
} from "contexts/WorkspaceCreateContext";

import {
  getWorkspaceGroupObject,
  VERB_FINISHED_PHRASE,
  VERB_FAILED_PHRASE,
} from "./utils";
import {
  // InstanceSettingsSteps,
  VerbBuildComponent,
} from "../../Verb/VerbBuildComponent";
import { create, set } from "lodash";
import Chip from "components/Environment/Create/chips";
import BannerNotification from "components/BannerNotification";
import ShareSettings from "./Tabs/Access/Share";
import { StopModal } from "./DeprecatedSettings/InstanceSettings/StopModal";
import TabNavigator from "./TabNavigator";
import MachineSettings from "./DeprecatedSettings/MachineSettingsTab";
import JupyterNotebookFetcher from "./DeprecatedSettings/InstanceSettings/JupyterNotebookFetcher";
import FlatCard from "components/UI-lib/FlatCard";
import OpenEnvironment from "./Tabs/Access/SSH";
import Deployments from "./Tabs/Access/Tunnels";
import ComputeSettings from "./Tabs/Compute";
import NotebookSettings from "./Tabs/Notebook";
import Access from "./Tabs/Access/Tunnels/TunnelSection/Access";
import AccessSettings from "./Tabs/Access";
import ContainerSettings from "./Tabs/Container";
import {
  displayPlatformType,
  formatMemory,
  getInstancePriceRate,
  getStorageCostNumber,
  isGPUfromInstanceType,
} from "../utils";
import { getVerbBuildConfigFromWorkspace } from "components/Verb/utils";
import ContainerOverview, {
  SimplifiedContainerOverview,
} from "../shared/ContainerOverview";
import { OneClickDeployContext } from "contexts/OneClickDeployContext";
import { buildDefaultVerbYamlConfig } from "components/Verb/utils";
import InstanceStatusIndicator from "../shared/InstanceStatusIndicator";
import ConstanterStatusIndicator from "../shared/ContainerStatusIndicator";
import { FailedStartModal } from "./FailedStartModal";
import { Spinner } from "@kui-react/spinner";
import WorkspaceActions from "./WorkspaceActions";

/*
                               _
              _        ,-.    / )
             ( `.     // /-._/ /
              `\ \   /(_/ / / /
                ; `-`  (_/ / /
                |       (_/ /
                \          /
                 )        /
      Brev.Dev   /       /
*/

interface ParamTypes {
  workspaceId: string;
  page?: string;
}

interface LocationState {
  message?: string;
  messageSeverity?: any;
  cuda?: string;
  python?: string;
}

const getJupyterHealthcheck = (workspace, jupyterApp) =>
  workspace?.healthCheck?.find(
    (hc) => hc.health_check_id === jupyterApp?.healthCheckID
  );

const isHealthyPolicy1 = (
  workspace: Workspace,
  jupyterApp,
  jupyterHealthcheck
): boolean => {
  return !!(
    workspace &&
    workspace.status === WorkspaceStatus.Running &&
    workspace.healthCheck &&
    workspace.healthCheck.length > 0 &&
    workspace.verbBuildStatus === VerbBuildStatus.Completed &&
    jupyterApp != undefined &&
    jupyterHealthcheck?.status === "healthy"
  );
};

const isHealthyPolicy2 = (
  workspace: Workspace,
  jupyterApp,
  jupyterHealthcheck
): boolean => {
  const basicInstanceHealthyStatus = !!(
    workspace &&
    workspace.status === WorkspaceStatus.Running &&
    workspace.healthCheck &&
    workspace.healthCheck.length > 0 &&
    workspace.verbBuildStatus === VerbBuildStatus.Completed
  );
  const jupyterHealthyStatus = !!(
    jupyterApp != undefined &&
    jupyterHealthcheck?.status === "healthy" &&
    workspace &&
    workspace.tunnel &&
    workspace.tunnel.tunnelStatus === "HEALTHY"
  );
  return basicInstanceHealthyStatus && jupyterHealthyStatus;
};

const EnvironmentSettings: React.FC = (props) => {
  console.log("Route: /org/:orgId/environments/:deploymentId/:page");

  const [workspace, setWorkspace] = useState<Workspace | null>(null);
  const [events, setEvents] = useState<EnvEvent[]>([]);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation<LocationState>();
  const [stopLoading, setStopLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const wsContext = useContext(WorkspaceContext);
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const oneClickDeployContext = useContext(OneClickDeployContext);
  const history = useHistory();
  const [showPopUp, setShowPopUp] = useState(false);
  const { workspaceId, page } = useParams<ParamTypes>();
  const [timeframe, setTimeframe] = useState<Timeframe>("all");
  const notificationContext = useContext(NotificationContext);
  const [jupyterApp, setJupyterApp] = useState<IApplication>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const createContext = useContext(WorkspaceCreateContext);
  const [showButton, setShowButton] = useState(false);
  const [buildTriggeredNoErrors, setBuildTriggeredNoErrors] = useState(false);
  // Flag to determine if the new logic should be used.
  // https://us.posthog.com/project/22059/feature_flags/44359
  const flagUseTunnelStatusForNotebookHealth = useFeatureFlagEnabled(
    "use-tunnel-status-for-notebook-health"
  );

  const hash = window?.location?.hash?.slice(1) ?? "";
  const validStates = ["Compute", "Container", "Notebook", "Access"];
  const [currentState, setCurrentState] = useState(
    validStates.includes(hash) ? hash : "Access"
  );
  const [showStartFailedModal, setShowStartFailedModal] = useState(false);

  useEffect(() => {
    window.location.hash = currentState;
  }, [currentState]);

  const [initialVerbLoad, setInitialVerbLoad] = useState(false);
  const [notebookList, setNotebookList] = useState<any[]>([]);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    const loadProject = async () => {
      setLoading(true);
      const ws = await wsContext.getWorkspace(workspaceId);
      if (ws) {
        setWorkspace(ws);
      } else {
        notificationContext.showNotification(
          "Could not find instance. Return to the console and try again.",
          "",
          "error"
        );
      }
      setLoading(false);
    };
    loadProject();
  }, [workspaceId]);

  useEffect(() => {
    if (workspace?.tunnel?.applications) {
      const jupyterApp = workspace.tunnel.applications.find(
        (app) =>
          app.name.startsWith("jupyter") &&
          app.name.endsWith("-" + workspace.id)
      );
      setJupyterApp(jupyterApp);
    }
  }, [workspace?.tunnel?.applications]);

  //Auto Build Verb Container
  useEffect(() => {
    if (
      !initialVerbLoad &&
      workspace &&
      workspace.version == "v0" && // legacy workspace
      !workspace.vmOnlyMode &&
      workspace.verbBuildStatus === VerbBuildStatus.Unset &&
      (workspace?.status === WorkspaceStatus.Running ||
        workspace?.workspaceGroupId === "akash-brev-wg") &&
      !isCPU_techdebt(workspace.instanceType || "") &&
      workspace.id !== oneClickDeployContext.workspace?.id
    ) {
      console.log(workspace);
      const customYaml = buildDefaultVerbYamlConfig(
        workspace?.baseImage || "",
        workspace?.portMappings || null
      );
      buildVerbContainer(customYaml);
    }
  }, [
    initialVerbLoad,
    workspace?.status,
    workspace?.verbBuildStatus,
    workspace?.baseImage,
  ]);

  const buildVerbContainer = async (customYaml = "") => {
    createContext.setBuildButtonDisabled(true);
    createContext.setLogFileContent("");
    let res = await createContext.BuildVerbContainerOnWorkspace(
      workspaceId,
      customYaml || createContext.verbYaml
    );
    if (res.success && res.data) {
      // setBuildTriggeredNoErrors(true);
      createContext.setHitBuildButton(true);
    } else {
      // handle this error
      console.log(res);
    }
  };

  const instanceAttributes = useMemo(() => {
    const availableInstance = orgContext.allInstancesAvailable.find(
      (instance) => instance.type === workspace?.instanceType
    );
    if (availableInstance) {
      return availableInstance;
    }
    return workspace?.instanceTypeInfo;
  }, [workspace, orgContext.allInstancesAvailable]);

  useEffect(() => {
    const fetchNotebooks = async () => {
      try {
        const notebookList = await fetchLegacyNotebookList();
        setNotebookList(notebookList);
      } catch (e) {
        console.log(e);
      }
    };
    fetchNotebooks();
  }, []);

  useEffect(() => {
    if (workspace?.tunnel?.applications) {
      const jupyterApp = workspace.tunnel.applications.find(
        (app) =>
          app.name.startsWith("jupyter") &&
          app.name.endsWith("-" + workspace.id)
      );
      setJupyterApp(jupyterApp);
    }
  }, [workspace?.tunnel?.applications]);

  const deleteWorkspace = async (workspaceId: string) => {
    setLoadingDelete(true);
    const res = await wsContext.deleteWorkspace(workspaceId);
    setLoadingDelete(false);
    if (res.success) {
      if (oneClickDeployContext.workspace?.id === workspaceId) {
        oneClickDeployContext.reset();
      }
      notificationContext.showNotification("Deleted Instance", "", "success");
      // if currently on workspace page, redirect to environments list
      if (history.location.pathname.includes("/environments/")) {
        history.push(`/org/${orgContext.activeOrgId}/environments`);
      }
    } else {
      notificationContext.showNotification(
        "Failed to delete instance",
        res.message,
        "error"
      );
    }
  };

  const { data: updatedWorkspace } = useQuery({
    queryKey: ["workspace", workspaceId],
    queryFn: async () => {
      const res = await agent.Workspaces.get(workspaceId);
      if (res.success && res.data) {
        return new Workspace(res.data);
      }
      return null;
    },
    refetchInterval: 3000,
    refetchIntervalInBackground: false,
    enabled: !!workspace,
    staleTime: 1000,
    onSuccess: (data) => {
      if (!data || !workspace) return;

      if (
        data.status !== workspace.status ||
        data.spot !== workspace.spot ||
        !!data.customContainer?.containerUrl !==
          !!workspace.customContainer?.containerUrl ||
        data.vmOnlyMode !== workspace.vmOnlyMode ||
        !isCheckHealthSame(
          data.healthCheck || [],
          workspace.healthCheck || []
        ) ||
        data.lastStartStatus !== workspace.lastStartStatus ||
        !!data.region !== !!workspace.region ||
        workspace.verbBuildStatus !== data.verbBuildStatus ||
        !areStringListsEqual(
          workspace.additionalUsers || [],
          data.additionalUsers || []
        ) ||
        workspace.baseImage !== data.baseImage ||
        workspace.tunnel?.tunnelStatus !== data.tunnel?.tunnelStatus ||
        workspace.dockerCompose?.fileUrl !== data.dockerCompose?.fileUrl
      ) {
        setWorkspace(data);
        const otherWorkspaces = wsContext.workspaces.filter(
          (ws) => ws.id !== data.id
        );
        wsContext.setWorkspaces([data, ...otherWorkspaces]);
      }
    },
  });

  const { data: verbLogData } = useQuery({
    queryKey: ["verbLog", workspaceId],
    queryFn: () => createContext.ReadLogFileFromMachine(workspaceId),
    refetchInterval: 3000,
    staleTime: 1000,
    refetchIntervalInBackground: false,
    enabled: !!(
      workspace &&
      workspace.status === WorkspaceStatus.Running &&
      (workspace.verbBuildStatus === VerbBuildStatus.Building ||
        workspace.verbBuildStatus === VerbBuildStatus.Pending ||
        workspace.verbBuildStatus === VerbBuildStatus.Completed ||
        workspace.verbBuildStatus === VerbBuildStatus.CreateFailed)
    ),
    onSuccess: (res) => {
      if (res && res.success) {
        setInitialVerbLoad(false);
        const content = res.data?.content;
        if (content === "\n") {
          createContext.setIsVerbBuilding(false);
        } else if (content?.includes(VERB_FINISHED_PHRASE)) {
          createContext.setIsVerbBuilding(false);
        } else if (content?.includes(VERB_FAILED_PHRASE)) {
          createContext.setIsVerbBuilding(false);
        } else {
          createContext.setIsVerbBuilding(true);
        }
      } else {
        createContext.setIsVerbBuilding(true);
      }
    },
  });

  const jupyterHealthcheck = useMemo(
    () => getJupyterHealthcheck(workspace, jupyterApp),
    [workspace, jupyterApp]
  );

  const isHealthy = useCallback(
    (workspace: Workspace): boolean => {
      return flagUseTunnelStatusForNotebookHealth
        ? isHealthyPolicy2(workspace, jupyterApp, jupyterHealthcheck)
        : isHealthyPolicy1(workspace, jupyterApp, jupyterHealthcheck);
    },
    [flagUseTunnelStatusForNotebookHealth, jupyterApp, jupyterHealthcheck]
  );

  const healthStatus = useMemo(
    () => (workspace ? isHealthy(workspace) : false),
    [workspace, isHealthy]
  );

  const isCheckHealthSame = (a: any[], b: any[]) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    if (a.length > 0 && b.length > 0) {
      return a[0].status === b[0].status;
    }
    return true;
  };

  function areStringListsEqual(
    list1: string[] | null | undefined,
    list2: string[] | null | undefined
  ): boolean {
    if (list1 == null && list2 == null) {
      return true;
    }
    if (list1 == null || list2 == null) {
      return false;
    }
    if (list1.length !== list2.length) {
      return false;
    }
    for (let i = 0; i < list1.length; i++) {
      if (list1[i] !== list2[i]) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (workspace?.name) {
      document.title = `${workspace.name} | Brev.dev`;
    } else {
      document.title = `Instance Settings | Brev.dev`;
    }
  }, [loading, workspace]);

  useEffect(() => {
    if (state && state.message && state.messageSeverity) {
      notificationContext.showNotification(
        state.message,
        "",
        state.messageSeverity
      );
    }

    // Add CUDA and Python loaded from template via query params
    let tempConfig = DEFAULT_VERB_CONFIG;
    if (state && state.cuda) {
      tempConfig.build.cuda = state.cuda;
    }
    if (state && state.python) {
      tempConfig.build.python_version = state.python;
    }
    createContext.setVerbYaml(yamlRawDump(tempConfig));
  }, []);

  const workspaceGroup = useMemo(() => {
    if (workspace && orgContext.workspaceGroups) {
      return getWorkspaceGroupObject(
        workspace?.workspaceGroupId || "",
        orgContext.workspaceGroups
      );
    }
    return null;
  }, [workspace?.workspaceGroupId, orgContext.workspaceGroups]);

  const { data: capabilities = [] } = useQuery({
    queryKey: [
      "capabilities",
      workspace?.workspaceGroupId,
      workspace?.workspaceCapabilities,
    ],
    queryFn: async () => {
      if (!workspace?.workspaceGroupId) return [];

      const res = await agent.Organizations.getCapabilities(
        workspace.workspaceGroupId
      );
      if (res.success && res.data) {
        const instanceCapabilities = res.data.capabilities as Capability[];
        if (workspace.workspaceCapabilities) {
          return instanceCapabilities.concat(workspace.workspaceCapabilities);
        }
        return instanceCapabilities;
      }
      if (workspace.workspaceCapabilities) {
        return workspace.workspaceCapabilities;
      }
      return [];
    },
    enabled: !!workspace?.workspaceGroupId,
  });

  const openNotebook = async (workspace: Workspace) => {
    agent.Brevent.track({
      eventName: "Opened Notebook",
      userId: userContext.me?.id,
      properties: {
        environmentId: workspace.id,
      },
    });

    if (workspace.fileObjects) {
      let fileList = mapValuesToArray(workspace.fileObjects);
      let file = fileList[0];
      if (file && !workspace.verbYaml?.includes("workingDirectory")) {
        window.open(
          "https://" +
            jupyterApp?.hostname +
            "/lab/tree" +
            (file.path.startsWith("/") ? "" : "/") +
            file.path,
          "_blank"
        );
      } else {
        window.open("https://" + jupyterApp?.hostname, "_blank");
      }
    } else {
      window.open("https://" + jupyterApp?.hostname, "_blank");
    }
  };

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="min-h-full">
        {/* Page heading */}
        <header className="pt-8 pb-3 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
            <div className="flex-1 min-w-0 pb-3">
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <a
                        onClick={() => {
                          history.push("/");
                        }}
                        className="text-sm font-medium text-gray-500 dark:text-secondary hover:text-gray-700 dark:hover:text-gray-300 cursor-pointer"
                      >
                        {orgContext.activeOrg?.name}
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <a className="ml-4 text-sm font-medium text-gray-500 dark:text-secondary">
                        {workspace?.name}
                      </a>
                    </div>
                  </li>
                </ol>
              </nav>
              <div className="flex flex-row justify-between items-end">
                <div className="flex fle-row items-end">
                  <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 dark:text-gray-100 sm:text-2xl sm:tracking-tight sm:truncate">
                    {workspace?.name}
                  </h1>
                  {workspace && (
                    <div className="ml-3 mt-2 flex items-center text-sm text-secondary mb-[2px]">
                      Created
                      {` ${new Date(workspace?.createdAt).toLocaleString()}`}
                    </div>
                  )}
                </div>
                <div className="flex items-center space-x-3"></div>
              </div>
              {workspace && (
                <>
                  <div className="flex flex-col sm:flex-row justify-between text-secondary">
                    <div className="flex flex-col sm:flex-row">
                      {workspace.instanceType && (
                        <div className="mt-2 flex items-center text-sm">
                          <CPUIcon className="h-5 w-5 mr-1" />
                          <InstanceAttribute
                            instanceType={workspace.instanceType}
                            isGPUInstance={isGPUfromInstanceType(
                              workspace.instanceType,
                              orgContext.allInstancesAvailable
                            )}
                            gpuName={
                              instanceAttributes &&
                              instanceAttributes?.supported_gpus
                                ? instanceAttributes?.supported_gpus[0].name
                                : ""
                            }
                            gpuManufacturer={
                              instanceAttributes &&
                              instanceAttributes?.supported_gpus
                                ? instanceAttributes?.supported_gpus[0]
                                    .manufacturer
                                : ""
                            }
                            vcpu={instanceAttributes?.vcpu}
                            memory={formatMemory(
                              instanceAttributes?.memory || "",
                              instanceAttributes &&
                                instanceAttributes?.workspace_groups &&
                                instanceAttributes?.workspace_groups.length > 0
                                ? instanceAttributes?.workspace_groups[0]
                                    .platformType || ""
                                : ""
                            )}
                            gpuMemory={
                              instanceAttributes &&
                              instanceAttributes?.supported_gpus
                                ? instanceAttributes?.supported_gpus[0].memory
                                : ""
                            }
                            gpuCount={
                              instanceAttributes &&
                              instanceAttributes?.supported_gpus
                                ? instanceAttributes?.supported_gpus[0].count
                                : 0
                            }
                          ></InstanceAttribute>
                        </div>
                      )}
                      {workspace.diskStorage && (
                        <div className="mt-2 flex items-center text-sm mr-3">
                          <DatabaseIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary"
                            aria-hidden="true"
                          />
                          {workspace.status === WorkspaceStatus.Deploying
                            ? "syncing..."
                            : workspace.diskStorage}
                        </div>
                      )}
                      {workspace.region && workspace.workspaceGroupId && (
                        <div className="mt-2 flex items-center text-sm text-secondary mr-3">
                          <MapPin
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {regionToReadable[workspace.region] ||
                            workspace.region}
                          <span className="mr-1.5 ml-1.5">|</span>
                          <CloudIcon className="w-5 h-5 mr-2" />
                          {workspaceGroup && (
                            <span>
                              {workspaceGroup.tenantType === "shared"
                                ? `Brev Cloud (${displayPlatformType(
                                    workspaceGroup.platformType.toUpperCase()
                                  )})`
                                : ""}
                              {workspaceGroup.tenantType === "isolated"
                                ? `${displayPlatformType(
                                    workspaceGroup.platformType.toUpperCase()
                                  )} | ${workspaceGroup.id}`
                                : ""}
                            </span>
                          )}
                          {workspace.spot && (
                            <div className="text-xs flex text-secondary items-end ml-1">
                              (SPOT)
                            </div>
                          )}
                          <Tooltip
                            title={
                              workspace.status === WorkspaceStatus.Deploying ||
                              workspace.status === WorkspaceStatus.Starting ||
                              workspace.status === WorkspaceStatus.Running
                                ? "Running Instance: You pay for both the instance usage and its storage"
                                : "Stopped Instance: You only pay for storage"
                            }
                            placement="top"
                          >
                            <div className="flex cursor-pointer">
                              {workspace && instanceAttributes && (
                                <div className="flex items-center text-sm text-secondary">
                                  <span className="mr-1.5 ml-1.5">|</span>
                                  {workspace.status ===
                                    WorkspaceStatus.Deploying ||
                                  workspace.status ===
                                    WorkspaceStatus.Starting ||
                                  workspace.status ===
                                    WorkspaceStatus.Running ? (
                                    <PlayCircleIcon className="h-5 w-5 mr-1" />
                                  ) : (
                                    <PauseCircleIcon className="h-5 w-5 mr-1" />
                                  )}
                                  {"$" +
                                    getInstancePriceRate(
                                      Number(
                                        instanceAttributes.base_price?.amount ||
                                          0
                                      ),
                                      Number(
                                        getStorageCostNumber(
                                          workspace.diskStorage || ""
                                        ) || 0
                                      ),
                                      Number(
                                        instanceAttributes?.supported_storage[0]
                                          .price_per_gb_hr?.amount || 0
                                      ),
                                      workspace.status ===
                                        WorkspaceStatus.Deploying ||
                                        workspace.status ===
                                          WorkspaceStatus.Starting ||
                                        workspace.status ===
                                          WorkspaceStatus.Running
                                    )}
                                  /hr
                                </div>
                              )}
                            </div>
                          </Tooltip>
                        </div>
                      )}
                      {workspace && (
                        <div
                          className="mt-2 flex items-center text-sm text-secondary mr-4"
                          id="instance-status"
                        >
                          <InstanceStatusIndicator
                            workspaceStatus={workspace.status}
                          />
                        </div>
                      )}
                    </div>
                    {workspace && (
                      <WorkspaceActions
                        workspace={workspace}
                        capabilities={capabilities}
                        deleteWorkspace={deleteWorkspace}
                        loadingDelete={loadingDelete}
                      />
                    )}
                  </div>
                  {workspace &&
                    isGPUfromInstanceType(
                      workspace.instanceType || "",
                      orgContext.allInstancesAvailable
                    ) && (
                      <div className="flex flex-row items-center mt-3 justify-between">
                        {!workspace.vmOnlyMode ? (
                          <div className="flex flex-row items-center">
                            <RectangleGroupIcon className="h-6 w-6 text-gray-400 text-secondary mr-1.5" />
                            <SimplifiedContainerOverview
                              workspaceVerbYaml={workspace.verbYaml}
                              workspaceBaseImage={workspace.baseImage}
                              workspaceCustomContainer={
                                workspace.customContainer
                              }
                              workspaceDockerCompose={workspace.dockerCompose}
                            />
                            <>{console.log(workspace)}</>
                            {workspace && (
                              <div
                                className="flex flex-row items-center justify-center ml-4"
                                id="container-status"
                              >
                                <ConstanterStatusIndicator
                                  workspaceStatus={workspace.status}
                                  workspaceVerbBuildStatus={
                                    workspace.verbBuildStatus ||
                                    VerbBuildStatus.Unset
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {workspace && jupyterApp && (
                          <div>
                            <Tooltip
                              title={
                                !healthStatus
                                  ? "notebook not live (takes a couple of min after instance starts)"
                                  : ""
                              }
                              placement="top"
                            >
                              <span>
                                <Button
                                  className={classNames(
                                    healthStatus ? "pulse-ready-button" : ""
                                  )}
                                  label="Open Notebook"
                                  type={healthStatus ? "primary" : "secondary"}
                                  loading={false}
                                  //hrefInNewTab={"https://"+"jupyter-" + workspace.id + ".brevlab.com"}
                                  onClick={() => {
                                    openNotebook(workspace);
                                  }}
                                  disabled={!healthStatus}
                                  iconLeft={
                                    <BookOpenIcon
                                      className="-ml-1 mr-2 h-5 w-5 text-white"
                                      aria-hidden="true"
                                    />
                                  }
                                />
                              </span>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </header>
        {/* {workspace?.status === WorkspaceStatus.Running &&
          workspace?.verbBuildStatus === VerbBuildStatus.Completed && (
            <BannerNotification
              message={
                <>
                  We're currently experiencing rate-limits with web URLs for
                  notebooks. If you can't access a notebook →{" "}
                  <a
                    href="https://brev.dev/docs/how-to/notebook-troubleshooting"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    check out the docs to troubleshoot
                  </a>
                </>
              }
              isOpen={true}
            />
          )} */}
        {workspace?.status === WorkspaceStatus.Failure ? (
          <>
            {" "}
            <main className="pt-8 pb-16 sm:px-6 lg:px-8">
              {/* Ensures only user can access his own */}
              <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                {workspace?.createdByUserId === userContext.me?.id ? (
                  <>
                    <div className="bg-white dark:bg-zinc-900">
                      <div className="flex flex-col">
                        <span className="text-red-500 font-bold">
                          There was an error provisioning your instance:
                        </span>
                        <div>
                          <span className="text-red-500">
                            "
                            {parseStatusMessage(
                              workspace!.statusMessage
                            ).trim()}
                            "
                          </span>
                        </div>
                        <span className="text-red-500 font-bold">
                          Please retry creating a new instance on a different
                          cloud!
                        </span>
                        <div className="flex items-center space-x-3 mt-5">
                          <Button
                            label="Delete Workspace"
                            onClick={() => deleteWorkspace(workspace!.id)}
                            type="danger"
                            loading={loadingDelete}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </main>
          </>
        ) : (
          <>
            <main className="pb-16 sm:px-6 lg:px-8">
              <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="dark:bg-zinc-900 mb-8">
                  <div className="bg-gray-50 dark:bg-zinc-900">
                    <TabNavigator
                      workspace={workspace}
                      setCurrentState={setCurrentState}
                      currentState={currentState}
                    />
                  </div>
                </div>
                {currentState === "None" && (
                  <div className="flex justify-center w-full">
                    <FlatCard className="mt-3" isLoading={false}>
                      <p className="text-sm text-gray-700 dark:text-secondary font-mono">
                        You do not have access to this instance.
                      </p>
                    </FlatCard>
                  </div>
                )}
                {currentState === "Compute" && workspace && (
                  <>
                    <ComputeSettings
                      workspace={workspace}
                      workspaceCapabilities={capabilities}
                    />
                  </>
                )}
                {currentState === "Container" && workspace && (
                  <>
                    <ContainerSettings
                      workspaceId={workspace.id}
                      setShowButton={setShowButton}
                      workspace={workspace}
                      initialVerbLoad={initialVerbLoad}
                    />
                  </>
                )}
                {currentState === "Notebook" && workspace && (
                  <>
                    <NotebookSettings
                      notebookList={notebookList}
                      workspace={workspace}
                    />
                  </>
                )}

                {currentState === "Access" && workspace && (
                  <>
                    <AccessSettings
                      workspace={workspace}
                      workspaceCapabilities={capabilities}
                    />
                  </>
                )}
              </div>
            </main>
          </>
        )}
      </div>
      {/* <GenericModalContainer isOpen={true} /> */}
    </>
  );
};

export default EnvironmentSettings;
