import Spinner from "components/UI-lib/Spinner";
import { classNames } from "components/utils";
import { UserContext } from "contexts/UserContext";
import User from "../../entities/User.entity";
import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { Tooltip } from "@mui/material";

interface UsernameDisplayFieldProps {
  userId: string;
  visible?: boolean;
  className?: string;
}

const UsernameDisplayField: React.FC<UsernameDisplayFieldProps> = ({
  userId,
  visible = true,
  className = "",
}) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const userContext = useContext(UserContext);

  useEffect(() => {
    const loadUser = async () => {
      setLoading(true);
      const user = await userContext.getUser(userId);
      if (user) setUser(user);
      setLoading(false);
    };

    if (visible) loadUser();
  }, [userId, visible]);

  if (loading) {
    return <Spinner type="primary" />;
  }
  return (
    <Tooltip title={`${userId} | ${user?.email}`} placement="top">
      <span className={classNames("UsernameDisplayField", className)}>
        {user ? user?.username : `id:${userId}`}
      </span>
    </Tooltip>
  );
};

export default UsernameDisplayField;
