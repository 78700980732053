import DevToggle from "components/DevToggle";
import FlatCard from "components/UI-lib/FlatCard";
import { classNames } from "components/utils";
import { OrgContext, IOrgContext } from "contexts/OrgContext";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import agent, {
  BillingProfile,
  Invoice,
  UsageResp,
  UsageTableRow,
} from "server";
import Button from "components/UI-lib/Button";
import Modal from "components/Modals/Modal";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import InvoiceComponent from "./InvoiceComponent";
import Spinner from "components/UI-lib/Spinner";
import { DateRange } from "components/Graphs/DateRangePicker";
import { useQuery } from "@tanstack/react-query";

interface BillingProps {}

const Billing: React.FC<BillingProps> = () => {
  const orgContext = useContext(OrgContext);
  const [upcomingInvoice, setUpcomingInvoice] = useState<Invoice>();
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const [billingProfile, setBillingProfile] = useState<BillingProfile>();
  const [showPauseSubConfirmationModal, setShowPauseSubConfirmationModal] =
    useState(false);
  const [showResumeSubConfirmationModal, setShowResumeSubConfirmationModal] =
    useState(false);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [invoices, setInvoices] = useState([]);

  const [pauseLoading, setPauseLoading] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(false);
  const updateBillingProfile = async () => {
    const res = await agent.Organizations.getBillingProfile(
      orgContext.activeOrgId
    );
    if (res.data) {
      setBillingProfile(res.data);
    }
  };
  useEffect(() => {
    refreshInvoices(orgContext.activeOrgId);
    updateBillingProfile();
  }, [orgContext.activeOrgId]);

  const refreshInvoices = (activeOrgId: string) => {
    setInvoicesLoading(true);
    agent.Organizations.getInvoices(activeOrgId).then((invoices) => {
      if (invoices.data) {
        setInvoices(invoices.data.invoices as any);
      }
      setInvoicesLoading(false);
    });
    agent.Organizations.getUpcomingInvoice(activeOrgId).then((resp) => {
      if (resp.data) {
        setUpcomingInvoice(resp.data);
      }
    });
  };

  return (
    <FlatCard className="">
      <h1 className="text-xl mb-3 font-medium dark:text-white">Billing</h1>
      <h3 className="font-medium mb-1 dark:text-secondary">Invoices:</h3>
      <hr className="mt-1 mb-1 dark:border dark:border-zinc-800" />
      {invoicesLoading ? (
        <FlatCard isLoading={true}>
          <span className="text-sm text-gray-700 dark:text-secondary">
            Fetching invoices (this can take a moment)
          </span>
        </FlatCard>
      ) : (
        <InvoiceComponent
          invoices={invoices}
          upcomingInvoice={upcomingInvoice}
          refreshInvoices={refreshInvoices}
        />
      )}
      {orgContext.activeOrgId && orgContext.activeOrgId !== "" && (
        <BillingTable orgContext={orgContext} />
      )}
      <Modal
        setOpen={setShowPauseSubConfirmationModal}
        isOpen={showPauseSubConfirmationModal}
        onClose={() => setShowPauseSubConfirmationModal(false)}
        onSuccess={async () => {
          setPauseLoading(true);
          const res = await agent.Organizations.pauseSubscription(
            orgContext.activeOrgId
          );
          updateBillingProfile();
          if (!res.success) {
            setInlineAlertMessage(res.message);
            setInlineAlertSeverity("error");
            console.log(res);
          } else {
            console.log(res);
            setInlineAlertSeverity("success");
            setInlineAlertMessage("Your billing account has been paused");
          }
          setShowPauseSubConfirmationModal(false);
          setPauseLoading(false);
          // history.push(`/org/${orgContext.activeOrg?.id}/environments`);
          // setShowConfirmationModal(false);
        }}
        title="Pause billing ?"
        body={
          <div className="w-[450px]">
            <p className="text-sm text-gray-500 dark:text-secondary">
              Your outstanding balance will be charged and you will not be
              charged further. Your instances will be stopped and you will not
              be able to create instances until you hit "Restart Billing" after
              refreshing the page.
            </p>
          </div>
        }
        confirmLabel="Yes, pause billing"
      />
      <Modal
        setOpen={setShowResumeSubConfirmationModal}
        isOpen={showResumeSubConfirmationModal}
        onClose={() => setShowResumeSubConfirmationModal(false)}
        onSuccess={async () => {
          setResumeLoading(true);
          const res = await agent.Organizations.resumeSubscription(
            orgContext.activeOrgId
          );
          updateBillingProfile();
          if (!res.success) {
            setInlineAlertMessage(res.message);
            setInlineAlertSeverity("error");
            console.log(res);
          } else {
            console.log(res);
            setInlineAlertSeverity("success");
            setInlineAlertMessage("Your billing account has been restarted");
          }
          setShowResumeSubConfirmationModal(false);
          setResumeLoading(false);
        }}
        title="Restart billing ?"
        body={
          <div className="w-[450px]">
            <p className="text-sm text-gray-500 dark:text-secondary">
              Restart your billing account. This will let you launch meaty GPUs
              once again!
            </p>
          </div>
        }
        confirmLabel="Yes, restart billing"
      />

      <div className="mt-2">
        <InlineNotification
          show={!!inlineAlertMessage}
          severity={inlineAlertSeverity}
          className="w-full mb-2"
          text={inlineAlertMessage}
          // text2={<div className="mt-2 ml-[36px]">{inlineAlertAction}</div>}
          autoClose={false}
          onClose={() => {
            setInlineAlertMessage("");
            setInlineAlertSeverity("error");
          }}
        />
        {/* {billingProfile?.collection_status == "active" && (
          <Button
            label="Pause Billing Account"
            onClick={() => setShowPauseSubConfirmationModal(true)}
            loading={pauseLoading}
          />
        )} */}
        {billingProfile?.collection_status == "inactive" && (
          <Button
            label="Resume Billing"
            onClick={() => setShowResumeSubConfirmationModal(true)}
            loading={resumeLoading}
          />
        )}
      </div>
    </FlatCard>
  );
};

function calc(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

function getCurrentMonth() {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = new Date();
  const currentMonth = monthNames[currentDate.getMonth()];

  return currentMonth;
}

function BillingTable({ orgContext }: { orgContext: IOrgContext }) {
  const [dateRange, setDateRange] = useState<DateRange | undefined>(() => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    return {
      from: firstDay,
      to: now,
    };
  });

  const getUsage = async (orgId: string, dateRange: DateRange | undefined) => {
    const res = await agent.Organizations.getUsage(
      orgId,
      dateRange
        ? {
            from: dateRange.from || new Date(),
            to: dateRange.to || new Date(),
          }
        : undefined
    );
    if (!res.success || !res.data) {
      throw new Error("Failed to fetch usage data");
    }
    return res.data.Usage;
  };

  const { data: usageData, isLoading: usageLoading } = useQuery({
    queryKey: ["usage", orgContext.activeOrgId, dateRange?.from, dateRange?.to],
    queryFn: () => getUsage(orgContext.activeOrgId, dateRange),
    enabled: !!orgContext.activeOrgId && !!dateRange?.from && !!dateRange?.to,
  });
  const transformUsages = (usages: UsageResp[]): UsageTableRow[] => {
    const result: Record<string, UsageTableRow> = {};

    usages.forEach((usage) => {
      if (!result[usage.EnvID]) {
        result[usage.EnvID] = {
          EnvID: usage.EnvID,
          EnvName: usage.EnvName,
          ComputeCost: 0,
          StorageCost: 0,
          TotalCost: 0,
          CreatedByUserID: usage.CreatedByUserID,
        };
      }
      if (usage.UsageType === "compute") {
        result[usage.EnvID].ComputeCost = parseFloat(usage.Cost);
      } else if (usage.UsageType === "storage") {
        result[usage.EnvID].StorageCost = parseFloat(usage.Cost);
      }
      result[usage.EnvID].TotalCost =
        result[usage.EnvID].ComputeCost + result[usage.EnvID].StorageCost;
    });

    return Object.values(result);
  };

  const getTotalCost = () => {
    let totalCost = 0.0;
    usageData?.forEach((transaction) => {
      if (transaction.Cost) {
        totalCost += parseFloat(transaction.Cost);
      }
    });
    try {
      return `${calc(totalCost).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`;
    } catch (error) {
      return totalCost;
    }
  };

  return (
    <div className="mt-8 flex flex-col relative">
      <h3 className="font-medium mb-1 dark:text-secondary">
        Usage Summary: {getCurrentMonth()} {new Date().getFullYear()}
      </h3>
      {window.location.pathname !==
        `/org/${orgContext.activeOrgId}/settings` && (
        <div className="absolute top-0 right-0 m-2 flex flex-row items-center justify-evenly">
          <a
            href={`/org/${orgContext.activeOrgId}/settings`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row items-center"
          >
            <p className="mr-2">Expand</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </a>
        </div>
      )}
      <div className="mt-1 flex flex-col">
        <hr className="mt-1 mb-1 dark:border dark:border-zinc-800" />
        <div className="-my-2 -mx-4 overflow-y-visible sm:-mx-6 lg:-mx-8">
          <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-300 dark:divide-zinc-800  overflow-x-auto">
                <thead className="bg-gray-50 dark:bg-zinc-900">
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-secondary sm:pl-6"
                    >
                      Instance ID
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900dark:text-secondary"
                    >
                      Name
                    </th>
                    {/* <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900 dark:text-secondary"
                    >
                      Avg Instance Price / Hour
                    </th> */}
                    {window.location.pathname ==
                      `/org/${orgContext.activeOrgId}/settings` && (
                      <>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900 dark:text-secondary"
                        >
                          Total Compute
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900 dark:text-secondary"
                        >
                          Total Storage
                        </th>
                      </>
                    )}
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900 dark:text-secondary"
                    >
                      Total Cost
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-zinc-800 bg-white dark:bg-zinc-900">
                  {transformUsages(usageData ?? []).map((row) => (
                    <>
                      <TableRow row={row} orgContext={orgContext} />
                    </>
                  ))}
                  <tr key="t">
                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 dark:text-secondary sm:pl-6" />
                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 dark:text-secondary sm:pl-6" />
                    {window.location.pathname ==
                      `/org/${orgContext.activeOrgId}/settings` && (
                      <>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 dark:text-secondary sm:pl-6" />
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 dark:text-secondary sm:pl-6" />
                      </>
                    )}
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary text-right">
                      Total: {getTotalCost()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TableRow({
  row,
  orgContext,
}: {
  row: UsageTableRow;
  orgContext: IOrgContext;
}) {
  const history = useHistory();
  return (
    <tr>
      <td
        className={classNames(
          "whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6",
          "underline cursor-pointer text-cyan-800"
        )}
        onClick={() => {
          history.push(
            `/org/${orgContext.activeOrg?.id}/environments/${row.EnvID}/activity`
          );
        }}
      >
        {row.EnvID}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary">
        {/* {transaction.name} */}
        {row.EnvName}
      </td>
      {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary text-right">
        {row.InstancePrice ? `\$${row.InstancePrice}/hr ` : "N/A"}
      </td> */}
      {window.location.pathname ==
        `/org/${orgContext.activeOrgId}/settings` && (
        <>
          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary text-right">
            {row.ComputeCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </td>
          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary text-right">
            {row.StorageCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </td>
        </>
      )}{" "}
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary text-right">
        {row.TotalCost.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </td>
    </tr>
  );
}

export default Billing;
