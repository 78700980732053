import React from "react";
import AddDgxc from "./AddDgxc";
import SetResourceLimits from "./SetResourceLimits";

const Organizations: React.FC = () => {
  return (
    <div className="flex flex-col min-h-[50vh] pt-5 pl-24 pr-24">
      <div className="">
        <h1 className="text-3xl font-bold mb-2 text-white">Organizations</h1>
      </div>
      <hr className="w-[100%] mb-3 mt-3 dark:border dark:border-zinc-800" />
      <AddDgxc />
      <div className="mt-5">
        <SetResourceLimits />
      </div>
    </div>
  );
};

export default Organizations;
