import Modal from "components/Modals/Modal";
import React, { useContext, useEffect, useState, useRef } from "react";
import { OrgContext } from "contexts/OrgContext";
import { Transition } from "@headlessui/react";
import { set } from "lodash";
import InputField from "components/UI-lib/InputField";
import "./styles.scss";
import {
  CheckCircleIcon,
  PencilSquareIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { useWindupString } from "windups";
import { on } from "events";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { useHistory } from "react-router";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import Button from "components/UI-lib/Button";
import agent from "server";
import { classNames, timeout } from "components/utils";
import "./styles.scss";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { GenerateInviteLink } from "components/OrgPages/Teams/TeamInvite";

interface AddOrgModalProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  onSuccess: () => void;
  onCancel: () => void;
}
export const AddOrgModal: React.FC<AddOrgModalProps> = ({
  isOpen,
  setOpen = () => {},
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  const [view, setView] = useState<
    "CreateOrgName" | "AddMembers" | "CreateOrgFrame-3"
  >("CreateOrgName");
  const [name, setName] = useState("");

  const onClose = () => {
    setName("");
    setOpen(false);
    setView("CreateOrgName");
    onCancel();
  };

  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      onClose={onClose}
      title={""}
      body={
        <>
          <div className="w-[450px] ">
            <Transition
              show={
                view === "CreateOrgName" ||
                view === "AddMembers" ||
                view === "CreateOrgFrame-3"
              }
              enter="transition-opacity duration-200"
              enterFrom="opacity-0"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {view === "CreateOrgName" && (
                <CreateOrgName
                  onClose={onClose}
                  onSuccess={() => {
                    setView("AddMembers");
                  }}
                  name={name}
                  setName={(n) => setName(n)}
                  back={() => {}}
                />
              )}
              {view === "AddMembers" && (
                <AddMembers
                  onClose={onClose}
                  onSuccess={() => {
                    onClose();
                  }}
                  back={() => {
                    setView("CreateOrgName");
                  }}
                  name={name}
                />
              )}
            </Transition>
          </div>
        </>
      }
      cancelLabel="Cancel"
      hideCancel={true}
      confirmLabel={"Next"}
      hideSuccess={true}
    />
  );
};

interface CreateOrgNameProps {
  onClose: () => void;
  onSuccess: () => void;
  name: string;
  setName: (name: string) => void;
  back: () => void;
}

const CreateOrgName: React.FC<CreateOrgNameProps> = (props) => {
  const history = useHistory();
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("success");
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const orgContext = useContext(OrgContext);

  const handleCompletion = async () => {
    setInlineAlertMessage("");
    setLoading(true);
    const res = await orgContext.createOrSyncOrg(props.name);
    if (res.success && res.data) {
      const orgId = res.data?.id;
      history.push(`/org/${orgId}/environments`);
      setLoading(false);
      props.onSuccess();
    } else {
      setInlineAlertSeverity("error");
      setInlineAlertMessage(
        res.message || "Unable to create organization. Try again!"
      );
    }
    setLoading(false);
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <div className="flex items-center">
          <PencilSquareIcon className="h-5 w-5 text-white mr-2" />
          <h1 className="text-white">Name your new organization</h1>
        </div>
      </div>
      <p className="text-sm text-secondary mt-2">
        Securely collaborate on your projects by creating an organization.
      </p>
      <div className="flex flex-row w-full mb-2 mt-5">
        <InputField
          className="w-full"
          // validation={isEmoji}
          label=""
          value={props.name}
          placeholder="Organization Name"
          onChange={(v) => props.setName(v)}
          errorMessage={""}
        />
      </div>
      <InlineNotification
        className="mb-5"
        show={!!inlineAlertMessage}
        severity={inlineAlertSeverity || "info"}
        text={inlineAlertMessage}
        autoClose={false}
        onClose={() => setInlineAlertMessage("")}
      />
      <div className="flex flex-row justify-between mt-8">
        <div>
          <Button
            className="mr-2"
            type="secondary"
            onClick={props.onClose}
            label="Cancel"
            disabled={loading}
          ></Button>
        </div>
        <div>
          <Button
            label="Create"
            onClick={handleCompletion}
            disabled={props.name === "" || loading}
            loading={loading}
          ></Button>
        </div>
      </div>
    </>
  );
};

interface AddMembersProps {
  name: string;
  onClose: () => void;
  onSuccess: () => void;
  back: () => void;
}

const AddMembers: React.FC<AddMembersProps> = (props) => {
  const orgContext = useContext(OrgContext);
  const history = useHistory();
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("success");
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [showCustomField, setShowCustomField] = useState(false);
  // Add state hooks for email management
  const [emailList, setEmailList] = useState<string[]>([]);
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [didSendEmail, setDidSendEmail] = useState(false);
  const [fly, setFly] = useState(false);
  const [orgSuccess, setOrgSuccess] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setOrgSuccess(false);
    }, 3000);
  }, []);

  const handleCompletion = async () => {
    setInlineAlertMessage("");
    setLoading(true);
    const res = await agent.Organizations.inviteByEmail(
      orgContext.activeOrgId,
      emailList
    );
    if (res.success) {
      setLoading(false);
      setDidSendEmail(true);
      setFly(true);
      setEmailList([]);
      await timeout(2000);
      props.onSuccess();
      setFly(false);
    } else {
      setInlineAlertSeverity("error");
      setInlineAlertMessage(
        res.message || "Unable to add members to organization. Try again!"
      );
    }
    setLoading(false);
  };

  return (
    <>
      <InlineNotification
        className="mb-5"
        show={orgSuccess}
        severity={"success"}
        text={"Organization created successfully!"}
        autoClose={false}
        onClose={() => {
          setOrgSuccess(false);
        }}
      />
      <div className="flex flex-row items-center justify-between">
        <div className="flex items-center">
          <UserPlusIcon className="h-5 w-5 text-white mr-2" />
          <h1 className="text-white">
            (Optional) Add members to this organization
          </h1>
        </div>
      </div>
      <div className="flex flex-col w-full mb-2 mt-3 relative">
        <Transition
          show={true}
          // as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <GenerateInviteLink activeOrgId={orgContext.activeOrgId} />
        </Transition>
      </div>
      <div className="flex flex-row justify-between mt-8">
        <div></div>
        <div>
          <Button
            className="bg-highlight text-white px-4 py-2 rounded-md mr-2"
            onClick={props.onClose}
            disabled={loading}
            loading={loading}
            label="Done"
          ></Button>
        </div>
      </div>
    </>
  );
};
