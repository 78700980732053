/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Button from "components/UI-lib/Button";

import { OrgContext } from "contexts/OrgContext";
import { UserContext } from "contexts/UserContext";
import { PermissionHierarchyType } from "models/Permission.model";
import {
  classNames,
  displayPermissionHierarchyType,
  timeout,
} from "components/utils";
import { PermissionsContext } from "contexts/PermissionsContext";
import AccessCard from "components/AccessPanel/AccessCard";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { ReactMultiEmail } from "react-multi-email";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PaperAirplaneIcon,
  UserGroupIcon,
  UserPlusIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import CopyField from "components/UI-lib/CopyField";
import "./airplane.scss";
import {
  CONSOLE_1_AUTH0_DEPLOYMENT_URL,
  CONSOLE_1_KAS_DEPLOYMENT_URL,
  isKasAuthFlow,
} from "server/kas/utils";
import agent from "server";
import { useQuery } from "@tanstack/react-query";

const TeamInvite: React.FC = (props) => {
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const [showInviteLink, setShowInviteLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("success");
  const [focused, setFocused] = useState(false);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [didSendEmail, setDidSendEmail] = useState(false);
  const [fly, setFly] = useState(false);

  const handleEmailSend = async () => {
    setDidSendEmail(false);
    setFly(false);
    setInlineAlertMessage("");
    setLoading(true);
    const res = await orgContext.inviteByEmail(
      orgContext.activeOrgId,
      emailList
    );
    if (res.success) {
      setLoading(false);
      setDidSendEmail(true);
      setFly(true);
      setEmailList([]);
      await timeout(2000);
      setFly(false);
    } else {
      setInlineAlertSeverity("error");
      setInlineAlertMessage(
        "Unable to send email invite. Try again or Contact Support."
      );
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col bg-primaryBg dark:text-secondary rounded-md px-4 py-4 w-full">
      <div className="flex flex-row items-center">
        <UserPlusIcon className="w-5 h-5 mr-2" />
        <p className="text-md text-gray-700 dark:text-secondary">
          Invite new members by generating an invite link
        </p>
      </div>
      {orgContext.activeOrg?.isBlocked !== true &&
        userContext.me?.isBlocked !== true && (
          <>
            <div className="flex flex-col">
              <div className="flex flex-row">
                <Button
                  label="Generate Invite Link"
                  onClick={() => {
                    setShowInviteLink(!showInviteLink);
                  }}
                  type="primary"
                  className="mt-3"
                  style={{ marginRight: "10px" }}
                  iconRight={
                    showInviteLink ? (
                      <ChevronDownIcon className="w-5 h-5 ml-1" />
                    ) : (
                      <ChevronRightIcon className="w-5 h-5 ml-1" />
                    )
                  }
                />
              </div>
              <div className="mt-5">
                <Transition
                  show={showInviteLink}
                  // as={Fragment}
                  enter="transform ease-out duration-300 transition"
                  enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <GenerateInviteLink activeOrgId={orgContext.activeOrgId} />
                </Transition>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default TeamInvite;

interface GenerateInviteLinkProps {
  activeOrgId: string;
}

export const GenerateInviteLink: React.FC<GenerateInviteLinkProps> = ({
  activeOrgId,
}) => {
  const getInviteToken = async (activeOrgId: string) => {
    const res = await agent.Organizations.createInviteLink(activeOrgId);
    if (res.success && res.data) {
      return res.data;
    } else {
      throw new Error(res.message);
    }
  };

  const { data: inviteToken, isLoading: isLoading } = useQuery({
    queryKey: ["invite-token", activeOrgId],
    queryFn: () => getInviteToken(activeOrgId),
    enabled: !!activeOrgId,
  });

  return (
    <div className="flex flex-col justify-between">
      {inviteToken && inviteToken.length > 0 && (
        <>
          <h4 className="text-sm text-gray-900 dark:text-secondary">
            Copy the link and send it to your team to join your organization:
          </h4>
          <h6 className="text-xs text-gray-900 dark:text-secondary">
            (it expires in 7 days)
          </h6>
        </>
      )}
      <div className="mt-3 items-center">
        {inviteToken && inviteToken.length > 0 ? (
          <CopyField
            value={`${
              isKasAuthFlow
                ? CONSOLE_1_KAS_DEPLOYMENT_URL
                : CONSOLE_1_AUTH0_DEPLOYMENT_URL
            }/invite?token=${inviteToken}&orgID=${activeOrgId}`}
            style={{ width: "90%" }}
            analytics={{
              userid: "unused",
              eventLabel: "unused",
            }}
          />
        ) : (
          <h3 className="text-white">Generating link</h3>
        )}
      </div>
    </div>
  );
};
