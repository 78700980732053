import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { AdminUserType } from "components/Admin/types";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import agent from "server";
import { Spinner } from "@kui-react/spinner";
import Workspace from "entities/Workspace.entity";
import UsernameDisplayField from "components/UsernameDisplayField";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "components/Graphs/Tabs";
import { Capability } from "contexts/OrgContext";
import WorkspaceActions from "components/Environment/Settings/WorkspaceActions";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { NotificationContext } from "contexts/NotificationContext";
import ConstanterStatusIndicator from "components/Environment/shared/ContainerStatusIndicator";
import { VerbBuildStatus } from "models/Workspace.model";
import InstanceStatusIndicator from "components/Environment/shared/InstanceStatusIndicator";

const Instance: React.FC = () => {
  const { instanceId } = useParams<{ instanceId: string }>();
  const history = useHistory();
  const [tab, setTab] = useState<
    "Raw Workspace JSON" | "Raw Instance Type JSON"
  >("Raw Workspace JSON");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const wsContext = useContext(WorkspaceContext);
  const notificationContext = useContext(NotificationContext);

  const getInstanceAsAdmin = async () => {
    const res = await agent.Workspaces.get(instanceId);
    if (res.success && res.data) {
      return new Workspace(res.data);
    } else {
      throw new Error(res.message);
    }
  };

  const getInstanceFromType = async (
    instanceType: string,
    workspaceGroupID: string
  ): Promise<GPUInstanceType[] | null> => {
    const res = await agent.Instances.getInstanceByType(
      instanceType,
      workspaceGroupID
    );
    if (res.success && res.data && res.data.length > 0) {
      return res.data;
    }
    return null;
  };

  const {
    data: workspace,
    isLoading: isWorkspaceLoading,
    error: userError,
  } = useQuery({
    queryKey: ["admin-instance", instanceId],
    queryFn: getInstanceAsAdmin,
    refetchInterval: 5000,
    refetchIntervalInBackground: false,
    enabled: !!instanceId,
    staleTime: 2000,
  });

  // Then use workspace data in this dependent query
  const {
    data: instanceObject,
    isLoading: isInstanceLoading,
    error: instanceError,
  } = useQuery({
    queryKey: [
      "instance-type",
      workspace?.instanceType || "",
      workspace?.workspaceGroupId || "",
    ],
    queryFn: () =>
      getInstanceFromType(
        workspace?.instanceType || "",
        workspace?.workspaceGroupId || ""
      ),
    enabled: !!workspace?.instanceType && !!workspace?.workspaceGroupId,
    // Add this to ensure query only runs after workspace data is available
    staleTime: Infinity,
  });

  const { data: capabilities = [] } = useQuery({
    queryKey: [
      "capabilities",
      workspace?.workspaceGroupId,
      workspace?.workspaceCapabilities,
    ],
    queryFn: async () => {
      if (!workspace?.workspaceGroupId) return [];

      const res = await agent.Organizations.getCapabilities(
        workspace.workspaceGroupId
      );
      if (res.success && res.data) {
        const instanceCapabilities = res.data.capabilities as Capability[];
        if (workspace.workspaceCapabilities) {
          // join workspaceCapabilities and instanceCapabilities
          return instanceCapabilities.concat(workspace.workspaceCapabilities);
        }
        return instanceCapabilities;
      }
      if (workspace.workspaceCapabilities) {
        return workspace.workspaceCapabilities;
      }
      return [];
    },
    enabled: !!workspace?.workspaceGroupId,
  });

  const deleteWorkspace = async (workspaceId: string) => {
    setLoadingDelete(true);
    const res = await wsContext.deleteWorkspace(workspaceId);
    setLoadingDelete(false);
    if (res.success) {
      notificationContext.showNotification("Deleted Instance", "", "success");
    } else {
      notificationContext.showNotification(
        "Failed to delete instance",
        res.message,
        "error"
      );
    }
  };

  return (
    <div className="flex flex-col min-h-[50vh] pt-5 pl-24 pr-24">
      {isWorkspaceLoading ? (
        <div className="flex flex-col items-center justify-center h-full mt-10">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex flex-col mb-8">
            <div
              className="flex flex-row items-center cursor-pointer mb-5"
              onClick={() => {
                history.push("/admin/instances");
              }}
            >
              <ArrowLeftIcon className="w-4 h-4 text-highlight mr-1" />
              <h3 className="text-md font-bold text-highlight">Instances</h3>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-col">
                <span className="block min-w-0 flex-1">
                  <span className="block truncate text-lg font-medium text-white">
                    {workspace?.name}
                  </span>
                  <div className="flex flex-row text-secondary text-xs">
                    <span className="block truncate text-xs">
                      ID: {workspace?.id}
                    </span>
                    <span className="mr-1.5 ml-1.5 leading-[16px]">
                      &middot;
                    </span>
                    <span className="mr-1.5 leading-[16px]">User: </span>
                    <UsernameDisplayField
                      className="text-xs text-secondary"
                      userId={workspace?.createdByUserId || ""}
                    />
                    <span className="ml-1 leading-[16px]">
                      ({workspace?.createdByUserId || ""})
                    </span>
                    <span className="mr-1.5 ml-1.5 leading-[16px]">
                      &middot;
                    </span>
                    <span className="block truncate text-xs text-secondary">
                      Created:{" "}
                      {` ${new Date(
                        workspace?.createdAt || ""
                      ).toLocaleString()}`}
                    </span>
                  </div>
                </span>
              </div>
              <div className="flex flex-row">
                {workspace && (
                  <WorkspaceActions
                    workspace={workspace}
                    capabilities={capabilities}
                    deleteWorkspace={deleteWorkspace}
                    loadingDelete={loadingDelete}
                  />
                )}
              </div>
            </div>
            {workspace && (
              <div className="flex flex-col mt-3 mb-3">
                <div className="flex flex-row mb-3 items-end">
                  <span className="text-xs text-white mr-3 font-bold">
                    Instance Status:{" "}
                  </span>
                  <div
                    className="mt-2 flex items-center text-sm text-secondary"
                    id="instance-status"
                  >
                    <InstanceStatusIndicator
                      workspaceStatus={workspace.status}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-end">
                  <span className="text-xs text-white mr-3 font-bold">
                    Container Status:{" "}
                  </span>
                  <div
                    className="flex flex-row items-center justify-center"
                    id="container-status"
                  >
                    <ConstanterStatusIndicator
                      workspaceStatus={workspace.status}
                      workspaceVerbBuildStatus={
                        workspace.verbBuildStatus || VerbBuildStatus.Unset
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <hr className="w-[100%] mb-3 mt-3 dark:border dark:border-zinc-800" />
            <div className="mt-10">
              <Tabs
                value={tab}
                onValueChange={(val) => {
                  setTab(
                    val as "Raw Workspace JSON" | "Raw Instance Type JSON"
                  );
                }}
              >
                <TabsList variant="line">
                  <TabsTrigger value="Raw Workspace JSON">
                    Raw Workspace JSON
                  </TabsTrigger>
                  <TabsTrigger value="Raw Instance Type JSON">
                    Raw Instance Type JSON
                  </TabsTrigger>
                </TabsList>
                <div className="mt-8">
                  <TabsContent value="Raw Workspace JSON">
                    {isWorkspaceLoading ? (
                      <div className="flex flex-col items-center justify-center h-full mt-10">
                        <Spinner />
                      </div>
                    ) : (
                      <div className="mt-10">
                        <div className="max-w-[72vw] border border-zinc-800 rounded-md p-4">
                          <div className="overflow-x-auto">
                            <pre className="text-xs text-secondary">
                              {JSON.stringify(workspace, null, 2)}
                            </pre>
                          </div>
                        </div>
                      </div>
                    )}
                  </TabsContent>
                  <TabsContent value="Raw Instance Type JSON">
                    {isInstanceLoading ? (
                      <div className="flex flex-col items-center justify-center h-full mt-10">
                        <Spinner />
                      </div>
                    ) : (
                      <div className="mt-10">
                        <div className="max-w-[72vw] border border-zinc-800 rounded-md p-4">
                          <div className="overflow-x-auto">
                            <pre className="text-xs text-secondary">
                              {JSON.stringify(instanceObject, null, 2)}
                            </pre>
                          </div>
                        </div>
                      </div>
                    )}
                  </TabsContent>
                </div>
              </Tabs>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Instance;
