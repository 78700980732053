import React, { useContext, useEffect, useMemo, useState } from "react";
import Chip from "../chips";
import { classNames } from "components/utils";
import "../chips/chips.scss";
import { OrgContext } from "contexts/OrgContext";
export interface ChipType {
  name: string;
  manufacturer: string;
}

export const preferenceList: ChipType[] = [
  { name: "GH200", manufacturer: "nvidia" },
  { name: "H100", manufacturer: "nvidia" },
  { name: "A100", manufacturer: "nvidia" },
  { name: "L40S", manufacturer: "nvidia" },
  { name: "A10", manufacturer: "nvidia" },
  { name: "A10G", manufacturer: "nvidia" },
  { name: "L4", manufacturer: "nvidia" },
  { name: "T4", manufacturer: "nvidia" },
  { name: "L40", manufacturer: "nvidia" },
  { name: "A40", manufacturer: "nvidia" },
  { name: "RTX A6000", manufacturer: "nvidia" },
];

interface ToggleButtonsProps {
  selectedChip: string | null;
  setSelectedChip: (selected: string) => void;
  selectedCloud: string;
  listOfChips?: ChipType[];
  loading?: boolean;
  isLegacyPicker?: boolean;
}

const ChipToggleButtons: React.FC<ToggleButtonsProps> = ({
  selectedChip,
  setSelectedChip,
  selectedCloud,
  loading = false,
  listOfChips = [],
  isLegacyPicker,
}) => {
  const { GPUInstancesAvailable } = useContext(OrgContext);

  const handleSelection = (chipName: string) => {
    setSelectedChip(chipName);
  };
  const sortedChips = useMemo(() => {
    const sortedList = [...listOfChips];
    sortedList.sort((a, b) => {
      const indexA = preferenceList.findIndex(
        (preference) => preference.name === a.name
      );
      const indexB = preferenceList.findIndex(
        (preference) => preference.name === b.name
      );
      if (indexA === -1 && indexB === -1) {
        return 0;
      } else if (indexA === -1) {
        return 1;
      } else if (indexB === -1) {
        return -1;
      } else {
        return indexA - indexB;
      }
    });
    return sortedList;
  }, [listOfChips]);

  const chipsToRender = sortedChips.length > 0 ? sortedChips : listOfChips;

  return (
    <div
      className={classNames(
        "flex flex-row items-start overflow-x-auto whitespace-nowrap"
      )}
    >
      {!loading &&
        !isLegacyPicker &&
        GPUInstancesAvailable.some(
          (instance) => instance.workspace_groups[0].id === "dgxc-gcp"
        ) && (
          <Chip
            key={"dgx-H100"}
            selected={selectedChip === "dgx-H100"}
            chipName={"H100"}
            manufacturerName={"NVIDIA"}
            onClick={() => handleSelection("dgx-H100")}
            className={classNames("h-[150px] mr-2 flex-shrink-0")}
            style={
              {
                zIndex: listOfChips.length + 1,
                opacity: loading ? 0 : 1,
                animation: loading
                  ? `stack 0.2s ease-in-out ${
                      listOfChips.length + 1 * 0.2
                    }s forwards`
                  : `fanOut 0.2s ease-in-out 0.1s forwards`,
                "--translateX": `${listOfChips.length + 1 * -115}px`,
              } as React.CSSProperties
            }
            dgx={true}
          />
        )}
      {selectedCloud !== "dgxc-gcp" &&
        chipsToRender.map((chip, index) => (
          <Chip
            key={chip.name}
            selected={selectedChip === chip.name}
            chipName={chip.name}
            manufacturerName={chip.manufacturer}
            onClick={() => handleSelection(chip.name)}
            className={classNames("h-[150px] mr-2 flex-shrink-0")}
            style={
              {
                zIndex: listOfChips.length - index,
                opacity: loading ? 0 : 1,
                animation: loading
                  ? `stack 0.2s ease-in-out ${index * 0.2}s forwards`
                  : `fanOut 0.2s ease-in-out 0.1s forwards`,
                "--translateX": `${index * -115}px`,
              } as React.CSSProperties
            }
          />
        ))}
    </div>
  );
};

export default ChipToggleButtons;
