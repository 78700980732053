import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { DatapondAttachment } from "components/Environment/shared/DatapondAttachments";
import FlatCard from "components/UI-lib/FlatCard";
import React, { useContext, useEffect, useMemo, useState } from "react";
import RamMemoryIcon from "assets/img/svg/ram-memory.svg?react";
import { AnimatePresence, motion } from "framer-motion";
import { classNames, roundPriceToTwoDigits } from "components/utils";
import { OrgContext } from "contexts/OrgContext";
import FilterBar from "./FilterBar";
import ChipToggleButtons from "../../ComputePicker/ChipToggleButtons";
import GPUCard from "../ComputeCard";
import { convertToGPUMap } from "../../utils";
import GPUFlatCard from "../ComputeFlatCard";
import ChipStack from "../../chips/ChipStack";
import ComputeLoader from "../ComputeLoader";
import posthog from "posthog-js";

import NvidiaDGX100 from "assets/img/png/dgx100.png";
import NvidiaDGXDataCenter from "assets/img/png/dgxDatacenter.png";
import { useWindupString } from "windups";
import Chip from "../../chips";
import { CloudIcon, PlayCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "components/UI-lib";
import { Button as ButtonUI } from "components/Graphs/Button";
import { dummyDGXInstanceObject } from "components/Environment/utils";
import DGXComputeCard from "./DGX/DGXComputeCard";
import { ContainerSelected } from "components/Environment/shared/BuildTypes";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/Graphs/Popover";
import { DateRange, DateRangePicker } from "components/Graphs/DateRangePicker";
import DevToggle from "components/DevToggle";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/Graphs/Select";

export interface AdvancedSelectorProps {
  setDisplayGPUInstances(instances: GPUInstanceType[]): void;
  displayGPUInstances: GPUInstanceType[];
  setSelectedInstance(instanceObj: GPUInstanceType | undefined): void;
  containerSelected: ContainerSelected;
  selectedCloudName: string;
  setSelectedCloudName: (cloudName: string) => void;
}

const AdvancedSelector: React.FC<AdvancedSelectorProps> = ({
  setDisplayGPUInstances,
  displayGPUInstances,
  setSelectedInstance,
  containerSelected,
  selectedCloudName,
  setSelectedCloudName,
}) => {
  const orgContext = useContext(OrgContext);

  const [selectedChip, setSelectedChip] = useState<string>("H100");

  const gpuMap = useMemo(() => {
    let gpuInstances = displayGPUInstances;
    return convertToGPUMap(gpuInstances);
  }, [displayGPUInstances]);

  const chipList = useMemo(() => {
    return Object.keys(gpuMap).map((chipName) => {
      return {
        name: chipName,
        manufacturer: gpuMap[chipName][0].supported_gpus[0].manufacturer,
      };
    });
  }, [gpuMap]);

  useEffect(() => {
    if (
      chipList.length === 0 ||
      chipList.find((chip) => chip.name === selectedChip) ||
      selectedChip === "dgx-H100"
    )
      return;
    setSelectedChip(chipList[0].name);
  }, [chipList, selectedChip]);

  return (
    <>
      <div className="flex">
        <ChipToggleButtons
          selectedChip={selectedChip}
          setSelectedChip={(chipName) => {
            setSelectedChip(chipName);
          }}
          selectedCloud={selectedCloudName}
          listOfChips={chipList}
          // loading={true}
          loading={orgContext.isInstancesLoading}
        />
      </div>
      <hr className="w-[100%] my-5 dark:border dark:border-zinc-800" />
      <div className="flex flex-col">
        <div className="flex flex-col gap-3">
          {selectedChip !== "dgx-H100" ? (
            <DisplaySetup
              displayGPUInstances={displayGPUInstances
                .filter(
                  (instance) => instance.supported_gpus[0].name === selectedChip
                )
                .filter((instance) =>
                  !!containerSelected.vmBuild ||
                  !!containerSelected.customContainer
                    ? instance.workspace_groups[0].platformType !== "akash"
                    : true
                )
                .sort((a, b) => {
                  return (
                    Number(a.base_price?.amount) - Number(b.base_price?.amount)
                  );
                })}
              setSelectedInstance={(instance) => {
                setSelectedInstance(instance);
                posthog.capture("Selected Instance", {
                  tab: "Advanced",
                  instanceType: instance?.type,
                  gpuName: instance?.supported_gpus
                    ? instance?.supported_gpus[0].name
                    : "",
                  cloudProvider: instance?.workspace_groups[0].platformType,
                });
              }}
            />
          ) : (
            <DGXSetup setSelectedInstance={setSelectedInstance} />
          )}
        </div>
      </div>
    </>
  );
};

export default AdvancedSelector;

interface DisplaySetupProps {
  displayGPUInstances: GPUInstanceType[];
  setSelectedInstance(instanceObj: GPUInstanceType | undefined): void;
}

const DisplaySetup: React.FC<DisplaySetupProps> = ({
  displayGPUInstances,
  setSelectedInstance,
}) => {
  const orgContext = useContext(OrgContext);
  const [groupedBy, setGroupedBy] = useState<Record<string, GPUInstanceType[]>>(
    {}
  );
  const [dateRange, setDateRange] = useState<DateRange | undefined>(() => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return {
      from: now,
      to: tomorrow,
    };
  });
  const [nodes, setNodes] = useState<number>(2);
  const [nodesList, setNodesList] = useState<number[]>([2, 3, 4]);

  useEffect(() => {
    if (displayGPUInstances.length === 0) {
      console.log("No instances");
      setGroupedBy({});
      return;
    }
    let groupedBy = displayGPUInstances.reduce((acc, instance) => {
      if (!acc[instance.supported_gpus[0].count]) {
        acc[instance.supported_gpus[0].count] = [];
      }
      acc[instance.supported_gpus[0].count].push(instance);
      return acc;
    }, {} as Record<string, GPUInstanceType[]>);
    setGroupedBy(groupedBy);
  }, [displayGPUInstances]);

  return (
    <>
      {orgContext.isInstancesLoading ? (
        <ComputeLoader />
      ) : (
        <>
          {Object.keys(groupedBy).map((key) => (
            <div key={key} className="flex flex-col gap-3">
              <h2 className="text-lg font-medium leading-6 text-gray-900 dark:text-white my-3">
                {key}x {groupedBy[key][0].supported_gpus[0].manufacturer}{" "}
                {groupedBy[key][0].supported_gpus[0].name}
              </h2>
              {groupedBy[key].map((instance) => (
                <GPUFlatCard
                  key={instance.type}
                  instanceType={instance}
                  setInstanceType={setSelectedInstance}
                  chipName={instance.supported_gpus[0].name}
                  manufacturerName={instance.supported_gpus[0].manufacturer}
                  gpuCount={instance.supported_gpus[0].count}
                  vramSize={instance.supported_gpus[0].memory}
                  ramSize={String(instance.memory)}
                  cpuCount={instance.vcpu}
                  cloudProvider={instance.workspace_groups[0].platformType.toUpperCase()}
                  price={roundPriceToTwoDigits(
                    instance.base_price?.amount || "0.00"
                  )}
                />
              ))}
            </div>
          ))}
          <DevToggle>
            <h2 className="text-lg font-medium leading-6 text-gray-900 dark:text-white my-3">
              Multi Node
            </h2>
            <div className="flex flex-row w-full">
              <div className="flex flex-row">
                <FlatCard>
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-col items-center justify-center mb-3">
                      <h1 className="text-lg font-medium dark:text-white">
                        Make a Reservation
                      </h1>
                      <p className="text-xs text-secondary">
                        Powered by DGX Cloud
                      </p>
                    </div>
                    <div className="flex flex-row">
                      <Select
                        value={nodes.toString()}
                        onValueChange={(value) => {
                          setNodes(Number(value));
                        }}
                      >
                        <SelectTrigger id="size" className="min-w-[150px] mr-3">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {nodesList.map((item) => (
                            <SelectItem key={item} value={item.toString()}>
                              {item} Nodes of 8x NVIDIA H100
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <DateRangePicker
                        value={dateRange}
                        onChange={setDateRange}
                        className="min-w-[250px]"
                      />
                    </div>
                    <div className="flex flex-col items-center justify-center mt-3">
                      <Button
                        label={`Reserve for ${(
                          nodes *
                          40.63 *
                          ((dateRange?.to?.getDate() || 1) -
                            (dateRange?.from?.getDate() || 1))
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}`}
                        onClick={() => {}}
                        className="w-full text-center justify-center"
                      />
                    </div>
                  </div>
                </FlatCard>
              </div>
            </div>
          </DevToggle>
        </>
      )}
    </>
  );
};

interface DgxDisplaySetupProps {
  setSelectedInstance(instanceObj: GPUInstanceType | undefined): void;
}

const DGXSetup: React.FC<DgxDisplaySetupProps> = ({ setSelectedInstance }) => {
  const orgContext = useContext(OrgContext);
  const [showNext, setShowNext] = useState(false);
  const dgxFilteredInstances = orgContext.GPUInstancesAvailable.filter(
    (filteredInstances) => {
      return filteredInstances.workspace_groups[0].id === "dgxc-gcp";
    }
  );
  const [text] = useWindupString("No DGX Instances Available");

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNext(true);
    }, 2000);
    // Cleanup function
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col bg-black h-[380px] p-4 rounded-md relative">
      <div className="flex flex-row">
        <div className="flex flex-col justify-center items-center w-fit">
          <div className="flex flex-col w-full items-center">
            <div
              className="flex flex-row items-center"
              style={{
                color: "hsl(46.67deg 90% 68.63%)",
              }}
            >
              <svg
                width="24"
                height="16"
                viewBox="0 0 249 165"
                className="mr-1"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M25.43 70.902C25.43 70.902 47.934 37.699 92.867 34.264V22.218C43.098 26.215 0 68.367 0 68.367C0 68.367 24.41 138.932 92.867 145.393V132.589C42.63 126.269 25.43 70.902 25.43 70.902ZM92.867 107.125V118.851C54.899 112.082 44.36 72.614 44.36 72.614C44.36 72.614 62.59 52.419 92.867 49.144V62.011C92.844 62.011 92.828 62.004 92.809 62.004C76.918 60.097 64.504 74.942 64.504 74.942C64.504 74.942 71.462 99.933 92.867 107.125ZM92.867 0V22.218C94.328 22.106 95.789 22.011 97.258 21.961C153.84 20.054 190.707 68.367 190.707 68.367C190.707 68.367 148.364 119.855 104.25 119.855C100.207 119.855 96.422 119.48 92.867 118.85V132.589C95.907 132.975 99.059 133.202 102.348 133.202C143.399 133.202 173.086 112.237 201.832 87.424C206.598 91.241 226.11 100.527 230.121 104.592C202.789 127.475 139.09 145.921 102.977 145.921C99.496 145.921 96.153 145.71 92.867 145.393V164.699H248.899V0H92.867ZM92.867 49.144V34.264C94.313 34.163 95.77 34.086 97.258 34.039C137.946 32.761 164.64 69.004 164.64 69.004C164.64 69.004 135.808 109.047 104.894 109.047C100.445 109.047 96.457 108.332 92.867 107.125V62.011C108.707 63.925 111.894 70.922 121.417 86.797L142.597 68.938C142.597 68.938 127.136 48.661 101.073 48.661C98.24 48.66 95.529 48.859 92.867 49.144Z" />
              </svg>
              <span className="text-2xl font-bold">NVIDIA DGX Cloud</span>
            </div>
          </div>
          {/* <div className="mt-5">
            <img
              src={NvidiaDGX100}
              alt="NVIDIA NIM Influencer Banner"
              className="h-[300px] w-[300px]"
            />
          </div> */}
          <div className="mt-5">
            <img
              src={NvidiaDGXDataCenter}
              alt="NVIDIA NIM Influencer Banner"
              className="h-[300px] w-[500px]"
            />
          </div>
        </div>
        <div className="flex flex-col w-full space-y-6 ml-5 justify-center">
          {dgxFilteredInstances.length === 0 && (
            <div className="flex relative">
              <div className="flex flex-col items-center absolute top-0 w-full">
                <span className="text-xl font-bold text-slate-400">{text}</span>
              </div>
              <DGXComputeCard
                onClick={() => {}}
                disabled={true}
                instance={dummyDGXInstanceObject}
              />
            </div>
          )}
          {dgxFilteredInstances.map((instance) => (
            <>
              <DGXComputeCard
                onClick={() => {
                  if (
                    instance.quota.reserved.current &&
                    instance.quota.reserved.current > 0
                  ) {
                    setSelectedInstance(instance);
                  }
                }}
                disabled={false}
                instance={instance}
              />
              {showNext &&
                instance.quota.reserved.current &&
                instance.quota.reserved.current > 0 && (
                  <div className="absolute bottom-8 right-8 pulse-ready-button">
                    <Button
                      label={"Next"}
                      style={{
                        backgroundColor: "hsl(46.67deg 90% 68.63%)",
                        color: "#000",
                      }}
                      innerStyle={{ color: "#000" }}
                      onClick={() => {
                        setSelectedInstance(instance);
                      }}
                    />
                  </div>
                )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
