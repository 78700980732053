import React, { useContext, useEffect, useMemo, useState } from "react";
import FlatCard from "components/UI-lib/FlatCard";
import agent from "server";
import { OrgContext } from "contexts/OrgContext";
import { BillingContext } from "contexts/BillingContext";

function OrgResourceLimits() {
  const [resourceLimits, setResourceLimits] = useState("");
  const [currentResourceUsage, setCurrentResourceUsage] = useState("");
  const [resourcesRemaining, setResourcesRemaining] = useState("");
  const orgContext = useContext(OrgContext);
  const billingContext = useContext(BillingContext);

  return (
    <FlatCard>
      <h3 className="text-xl mb-3 font-medium dark:text-white">
        Resource Limit
      </h3>
      <p className="mb-2 text-sm text-gray-700 dark:text-secondary">
        Your organization has a resource usage limit of{" "}
        {(
          (billingContext.billingProfile?.resource_limits?.max_resources || 0) /
          100
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}{" "}
        per hour
      </p>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="bg-white dark:bg-zinc-900 dark:border-zinc-800 w-full h-5 rounded border-solid border flex items-center justify-start"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            width: `${
              (parseFloat(currentResourceUsage) / parseFloat(resourceLimits)) *
              100
            }%`,
          }}
          className={`text-base h-5 bg-highlight rounded dark:border-zinc-800 border-solid border-r `}
        ></div>
      </div>
      <div className="flex text-sm ml-1 mt-1">
        <p className="mb-2 text-sm text-gray-500 dark:text-secondary">
          Current usage: ${parseFloat(currentResourceUsage).toFixed(3)} per hour
        </p>
        <p className="mb-2 text-sm text-gray-500 dark:text-secondary ml-auto">
          Available usage: ${resourcesRemaining} per hour
        </p>
      </div>
      <div className="mb-1 mt-1 max-w-2xl text-sm text-gray-700 dark:text-secondary">
        Need more resources? Reach out to us at{" "}
        <a
          href="mailto:brev-support@nvidia.com"
          className="underline cursor-pointer"
        >
          brev-support@nvidia.com
        </a>
      </div>
    </FlatCard>
  );
}

export default OrgResourceLimits;
