import { IExec } from "models/Exec.model";
import IOrg, { AdminIOrganization } from "models/Organization.model";
import IPermission, { PermissionHierarchyType } from "models/Permission.model";
import { IRepo } from "models/Repo.model";
import IRole, { RoleIds } from "models/Role.model";
import ISecret, {
  DestConfig,
  DestType,
  HierarchyType,
  SrcConfig,
  SrcType,
  CreateSecretReq,
} from "models/Secret.model";
import IUser, { IdeConfig, ILoginToken, IUserMinimal } from "models/User.model";
import IWorkspace, { IFileObject, IFileType } from "models/Workspace.model";
import IWorkspaceTemplate from "models/WorkspaceTemplate.model";
import { WorkspaceClassId } from "../models/Workspace.model";
import { api, AxiosRes, BaseParams, BaseRes } from "./config";
import { Applications } from "./applications";
import {
  CustomContainer,
  DockerCompose,
} from "components/Environment/shared/BuildTypes";
import IOrganization from "models/Organization.model";
import { AdminOrganizationType, AdminUserType } from "components/Admin/types";

export interface TrackRequest {
  eventName: string;
  userId?: string;
  properties?: Record<string, unknown>;
}

interface UserLoginReqBody {
  username: string;
  password: string;
}

interface UserRefreshTokenReqBody {
  refresh_token: string;
}

type UsersMinimalRes = {
  data?: IUserMinimal[];
} & AxiosRes &
  BaseRes;
type UserMinimalRes = {
  data?: IUserMinimal;
} & AxiosRes &
  BaseRes;
export type CreateUserRes = {
  data?: {
    user: IUser;
    errorMessage: string;
  };
} & AxiosRes &
  BaseRes;

export type UserRes = {
  data?: IUser;
} & AxiosRes &
  BaseRes;

export type MultipleUserRes = {
  data?: IUser[];
} & AxiosRes &
  BaseRes;

export type LoginRes = {
  data?: ILoginToken;
} & AxiosRes &
  BaseRes;

type QueryByUsernameParams = {
  username: string;
} & BaseParams;

type QueryByAuthIDParams = {
  authID: string;
} & BaseParams;

export interface UserUpdateReqBody {
  username: string;
  name: string;
  email: string;
  baseWorkspaceRepo?: string;
  userSetupExecPath?: string;
  onboardingData?: {
    editor?: string;
    SSH?: boolean;
    usedCli?: boolean;
    copiedCliFromConsole?: boolean;
  };
  apiKeys?: {
    huggingFace: string;
    ngc: string;
  };
}

const Brevent = {
  // todo add workspace id
  openEvent: () => api.post("/api/brevent/open") as Promise<any>,
  track: (data: TrackRequest) => api.post("/api/brevent", data) as Promise<any>,
};

const Users = {
  auth0Register: (rawToken: string, inviteToken: string | null) => {
    const config = { headers: { Identity: rawToken } };
    console.log("auth0Register", config);
    return api.post(
      "/api/users",
      { inviteToken },
      config
    ) as Promise<CreateUserRes>;
  },
  me: () => api.get("/api/me") as Promise<UserRes>,
  getAllAsAdmin: () =>
    api.get("/api/users?all=true") as Promise<MultipleUserRes>,
  queryByUsername: (username: string) => {
    const params: QueryByUsernameParams = { username };
    return api.get("/api/users", { params }) as Promise<UsersMinimalRes>;
  },
  queryByAuthID: (authID: string) => {
    const params: QueryByAuthIDParams = { authID };
    return api.get("/api/users", { params }) as Promise<UserMinimalRes>;
  },
  getById: (id: string) => api.get(`/api/users/${id}`) as Promise<UserRes>,
  update: (id: string, updates: UserUpdateReqBody) =>
    api.put(`/api/users/${id}`, updates) as Promise<UserRes>,
  login: (username: string, password: string) => {
    const args: UserLoginReqBody = { username, password };
    return api.post("/api/token/login", args) as Promise<LoginRes>;
  },
  refresh: (refreshToken: string) => {
    const args: UserRefreshTokenReqBody = { refresh_token: refreshToken };
    return api.post("/api/token/refresh", args) as Promise<LoginRes>;
  },
};

type WorkspaceTemplateRes = {
  data?: IWorkspaceTemplate[];
} & AxiosRes &
  BaseRes;

const WorkspaceTemplate = {
  getAll: () =>
    api.get("/api/workspace_templates") as Promise<WorkspaceTemplateRes>,
};

type RolesRes = {
  data?: IRole[];
} & AxiosRes &
  BaseRes;

type RoleRes = {
  data?: IRole;
} & AxiosRes &
  BaseRes;

const Roles = {
  getAll: () => api.get("/api/roles") as Promise<RolesRes>,
  get: (id: string) => api.get(`/api/roles/${id}`) as Promise<RoleRes>,
};

export type BillingProfileDetails = {
  account_email?: string;
  accoun_name?: string;
};

export type CreditDetails = {
  top_up_threshold?: number; // in cents
  top_up_amount?: number; // in cents
  out_of_credits_threshold?: number; // in cents
  remaining_credits?: number; // in cents
};

export type BillingProfile = {
  billing_profile_id: string;
  create_time?: Date;
  update_time?: Date;
  labels: { [key: string]: string };
  billing_profile_meta?: BillingProfileDetails;
  external_account_id?: string;
  usage_labels: { [key: string]: string };
  usage_cloud_cred_labels: { [key: string]: string };
  collection_status?: string;
  inactive_at?: Date; // this is for when they have paused it or not
  delinquent_at?: Date;
  payment_status?: string;
  cloud_point_details?: {
    cloud_points_redeemable: number;
  };
  // BANANA
  creditBalance?: number;
  // BANANA
  billingThreshold?: number;
  // BANANA
  planIds: string[];
  credit_details?: CreditDetails;
  billing_type?: "credit" | "usage-arrears";
  resource_limits?: {
    max_resources?: number;
  };
};

export type Invoice = {
  id?: string;
  next_payment_attempt?: number;
  total?: number;
  tax?: number;
  subtotal?: number;
  customer_id?: string;
  status: string; // paid, void, draft, uncollectible, open
  currency?: string;
  amount_paid?: number;
  amount_due?: number;
  attempt_count?: number;
  invoice_url?: string;
  created?: number;
  invoice_id?: string;
  refund?: {
    refund_id: string;
    refunded_at: number;
    refund_url?: string;
    refund_status: string;
  };
};

type InvoiceRes = {
  data?: Invoice | undefined;
} & AxiosRes &
  BaseRes;

type BillingProfileRes = {
  data?: BillingProfile | undefined;
} & AxiosRes &
  BaseRes;

type UpdateBillingProfileRes = {
  data?: {
    billing_profile: BillingProfile;
  };
} & AxiosRes &
  BaseRes;

type SubscriptionRes = {
  data?: undefined;
} & AxiosRes &
  BaseRes;

type CreateDevplaneWorkspaceGroup = {
  name: string;
  organization_id: string;
  credential: {
    aws?: {
      access_key_id: string;
      secret_access_key: string;
      role_arn: string;
      external_id: string;
    };
    gcp?: {
      project_id: string;
      type: string;
      private_key_id: string;
      private_key: string;
      client_email: string;
      client_id: string;
      auth_uri: string;
      token_uri: string;
      auth_provider_x509_cert_url: string;
      client_x509_cert_url: string;
    };
    lambda_labs?: {
      api_key: string;
    };
  };
};

interface RetryInvoiceRequest {
  invoiceId: string;
}

interface CreateTemplateRequest {
  isPublic: boolean;
  workspaceRequest: CreateWorkspaceReqBody;
}

type RedemptionRes = {
  data?: {
    transaction: {
      amount_usd: string;
    };
  };
} & AxiosRes &
  BaseRes;

type CreditsRes = {
  data?: {
    balance_usd: number;
  };
} & AxiosRes &
  BaseRes;

// type OrgUsage struct {
// 	envID   string
// 	envName string
// 	cost    string
// }

export type UsageRes = {
  data?: {
    Usage: UsageResp[];
  };
} & AxiosRes &
  BaseRes;

export type UsageResp = {
  EnvID: string;
  EnvName: string;
  CreatedByUserID: string;
  Cost: string;
  InstancePrice: string;
  UsageType: string;
};

export type UsageOverviewRes = {
  data?: {
    Usage: UsageOverview[];
  };
} & AxiosRes &
  BaseRes;

export type UsageOverview = {
  Date: string;
  ComputeCost: number;
  StorageCost: number;
  InferenceCost: number;
};

export type UsageTableRow = {
  EnvID: string;
  EnvName: string;
  CreatedByUserID: string;
  ComputeCost: number;
  StorageCost: number;
  TotalCost: number;
};

export type WorkspaceGroupRes = {
  data?: WorkspaceGroup[];
} & AxiosRes &
  BaseRes;

export type RegionsRes = {
  data?: RegionWithWorkspaceGroup[];
} & AxiosRes &
  BaseRes;

export interface RegionWithWorkspaceGroup {
  name: string;
  description: string;
  available: string;
  workspaceGroup: WorkspaceGroup;
}

export interface WorkspaceGroup {
  id: string;
  name: string;
  baseDns: string;
  status: string;
  platform: string;
  platformId: string;
  platformRegion: string;
  platformType: string;
  version: string;
  tenantType: string;
  createdAt: string;
}

interface ValidateInviteArgs {
  token: string;
}
interface CreateOrgReqBody {
  name: string;
}

export type OrgRes = {
  data?: IOrg;
} & AxiosRes &
  BaseRes;

export type OrgsRes = {
  data?: IOrg[];
} & AxiosRes &
  BaseRes;

export type InviteLinkValidationRes = {
  data?: {
    valid: boolean;
  };
} & AxiosRes &
  BaseRes;

const Organizations = {
  getAll: () => api.get("/api/organizations") as Promise<OrgsRes>,
  getAllAsAdmin: (userid: string) =>
    api.get(`/api/organizations?user_id=${userid}`) as Promise<OrgsRes>,
  get: (orgId: string) =>
    api.get(`/api/organizations/${orgId}`) as Promise<OrgRes>,
  createOrSyncOrg: (
    name: string,
    ngcOrgName?: string,
    isInitialCall?: boolean
  ) => {
    const args: CreateOrgReqBody = { name };

    // if it's not the initial createOrSyncOrg call,
    // then we are creating an org from the UI
    // and it will be an "orphaned" brev org
    // (i.e. it will have no NCA or NGC org mapping)
    const config =
      ngcOrgName && isInitialCall
        ? {
            headers: {
              "X-NGC-ORG-NAME": ngcOrgName,
            },
          }
        : undefined;

    return api.post(`/api/organizations`, args, config) as Promise<OrgRes>;
  },
  delete: (id: string) =>
    api.delete(`/api/organizations/${id}`) as Promise<OrgRes>,
  createInviteLink: (id: string) =>
    api.get(`/api/organizations/${id}/invite`) as Promise<any>,
  validateInvite: (token: string) => {
    const args: ValidateInviteArgs = { token };
    return api.post(
      `/api/invite/verify`,
      args
    ) as Promise<InviteLinkValidationRes>;
  },
  connectCloud: (id: string) =>
    api.get(`/api/organizations/${id}/cloudformation_url`) as Promise<any>,
  createDevplaneWorkspaceGroup: (args: CreateDevplaneWorkspaceGroup) =>
    api.post(`/api/workspace_groups/devplane`, args) as Promise<any>,
  getWorkspaceGroups: (id: string) =>
    api.get(
      `/api/workspace_groups?organizationId=${id}`
    ) as Promise<WorkspaceGroupRes>,
  depracateWorkspaceGroup: (id: string) =>
    api.post(
      `/api/workspace_groups/${id}/deprecate`
    ) as Promise<WorkspaceGroupRes>,
  inviteByEmail: (id: string, inviteEmails: string[]) =>
    api.post(`/api/organizations/${id}/email_invite`, {
      inviteEmails,
    }) as Promise<any>,
  getWorkspaceGroupRegions: (id: string) =>
    api.get(`/api/workspace_groups/${id}/regions`) as Promise<RegionsRes>,
  getCapabilities: (id: string) =>
    api.get(`/api/workspace_groups/${id}/capabilities`) as Promise<any>,
  updateDefaultWorkspaceGroup: (id: string, wsGroup: WorkspaceGroup) =>
    api.put(`/api/organizations/${id}`, {
      defaultWorkspaceGroupId: wsGroup.id,
    }) as Promise<any>,
  getUsage: (id: string, dateRange?: { from: Date; to: Date }) => {
    let url = `/api/organizations/${id}/usage`;
    if (dateRange) {
      const params = new URLSearchParams({
        from: dateRange.from.toISOString(),
        to: dateRange.to.toISOString(),
      });
      url += `?${params.toString()}`;
    }
    return api.get(url) as Promise<UsageRes>;
  },
  getUsageOverview: (id: string) =>
    api.get(
      `/api/organizations/${id}/usage-overview`
    ) as Promise<UsageOverviewRes>,
  getUpcomingInvoice: (id: string) =>
    api.get(`/api/organizations/${id}/upcominginvoice`) as Promise<InvoiceRes>,
  getBillingProfile: (id: string) =>
    api.get(
      `/api/organizations/${id}/billingprofile`
    ) as Promise<BillingProfileRes>,
  pauseSubscription: (id: string) =>
    api.post(
      `/api/organizations/${id}/pausesubscription`
    ) as Promise<SubscriptionRes>,
  resumeSubscription: (id: string) =>
    api.post(
      `/api/organizations/${id}/resumesubscription`
    ) as Promise<SubscriptionRes>,
  getCredits: (id: string) =>
    api.get(`/api/organizations/${id}/credits`) as Promise<CreditsRes>,
  getInvoices: (id: string) =>
    api.get(`/api/organizations/${id}/invoices`) as Promise<any>,
  redeemCode: (id: string, code: string) =>
    api.post(`/api/organizations/${id}/credits/code/redeem`, {
      Code: code,
    }) as Promise<RedemptionRes>,
  createTemplate: (orgId: string, args: CreateTemplateRequest) =>
    api.post(`/api/organizations/${orgId}/template`, args) as Promise<any>,
  getRedeemedCredits: (id: string) =>
    api.get(`/api/organizations/${id}/credits/redeemed`) as Promise<any>,
  retryInvoice: (orgId: string, args: RetryInvoiceRequest) =>
    api.post(`/api/organizations/${orgId}/invoice/retry`, args) as Promise<any>,
  addDgxcToOrganization: (orgId: string) =>
    api.post(`/api/admin/org/${orgId}/add-dgxc`) as Promise<AxiosRes & BaseRes>,
};

//*******************************************
//************ BILLING CREDITS **************
//*******************************************
type CreditsResponse = {
  totalCredits: number;
} & AxiosRes;

type AddCreditsRequest = {
  amount: number; // Amount of credits to add
};
type AutoRechargeSettings = {
  threshold: number; // When the balance goes below this amount
  rechargeAmount: number; // Amount to recharge when balance goes below the threshold
};
type NotificationThreshold = {
  threshold: number; // When the balance goes below this amount, a notification is triggered
};

type AddCreditsResponse = {
  status: "Success" | "Failed";
  message?: string;
} & AxiosRes;

type AutoRechargeSettingsResponse = AutoRechargeSettings & AxiosRes;

type NotificationThresholdResponse = NotificationThreshold & AxiosRes;

type UpdateBillingProfileRequest = {
  top_up_amount?: string;
  top_up_threshold?: string;
};

type UpdateBillingProfileResponse = {
  status: "Success" | "Failed";
  message?: string;
} & AxiosRes;

type PurchaseCreditsRequest = {
  amount: string;
};

// TODO: SET THIS TO FALSE WHEN APIs ARE READY
const MOCK = false;

function mockResponse<T>(data: T, delay = 1000): Promise<T & AxiosRes> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        ...data,
        status: 200,
        statusText: "OK",
        success: true,
        message: "Mocked response",
      });
    }, delay);
  });
}

const BillingCredits = {
  getCredits: (orgId: string) =>
    MOCK
      ? mockResponse({ totalCredits: 500 })
      : (api.get(
          `/api/organizations/${orgId}/credits`
        ) as Promise<CreditsResponse>),

  getAutoRechargeSettings: (orgId: string) =>
    MOCK
      ? mockResponse({ threshold: 1000, rechargeAmount: 500 })
      : (api.get(
          `/api/organizations/${orgId}/credits/autorecharge`
        ) as Promise<AutoRechargeSettingsResponse>),

  // Get billing profile
  getBillingProfile: (orgId: string) =>
    api.get(
      `/api/organizations/${orgId}/billingprofile`
    ) as Promise<BillingProfileRes>,

  // Update billing profile
  updateBillingProfile: (orgId: string, args: UpdateBillingProfileRequest) =>
    MOCK
      ? mockResponse({
          status: "Billing Profile Updated",
          data: {
            billing_profile: {
              credit_details: { top_up_amount: 0, top_up_threshold: 0 },
            },
          },
        })
      : (api.put(
          `/api/organizations/${orgId}/billingprofile`,
          args
        ) as Promise<UpdateBillingProfileRes>),

  // Purchase credits (adjusted for POST and the request structure)
  purchaseCredits: (orgId: string, args: PurchaseCreditsRequest) =>
    MOCK
      ? mockResponse({ status: "Credits Purchased" })
      : (api.post(
          `/api/organizations/${orgId}/purchaseCredits`,
          args
        ) as Promise<AxiosRes>),

  // setNotificationThreshold: (orgId: string, threshold: NotificationThreshold) =>
  //   MOCK
  //     ? mockResponse({ threshold: 100 })
  //     : (api.post(
  //         `/api/organizations/${orgId}/credits/notificationthreshold`,
  //         threshold
  //       ) as Promise<NotificationThresholdResponse>),

  // getNotificationThreshold: (orgId: string) =>
  //   MOCK
  //     ? mockResponse({ threshold: 100 })
  //     : (api.get(
  //         `/api/organizations/${orgId}/credits/notificationthreshold`
  //       ) as Promise<NotificationThresholdResponse>),
};

interface MetricBodyReq {
  type: string;
}

interface PutPermissionReqBody {
  subject: string;
  role: RoleIds;
}

interface PutPermissionWithTokenReqBody {
  token: string;
}

export type PermissionsWithTokenRes = {
  data?: IPermission;
} & AxiosRes &
  BaseRes;

export type PermissionsRes = {
  data?: IPermission[];
} & AxiosRes &
  BaseRes;

const Permissions = {
  get: (hierarchy: PermissionHierarchyType, hierarchyId: string) =>
    api.get(
      `/api/${hierarchy}/${hierarchyId}/role_attachments`
    ) as Promise<PermissionsRes>,
  put: (
    hierarchy: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string,
    role: RoleIds
  ) => {
    const args: PutPermissionReqBody = { subject: subjectId, role };
    return api.put(
      `/api/${hierarchy}/${hierarchyId}/role_attachments`,
      args
    ) as Promise<PermissionsRes>;
  },
  putWithToken: (hierarchy: PermissionHierarchyType, token: string) => {
    const args: PutPermissionWithTokenReqBody = { token };
    return api.put(
      `/api/${hierarchy}/role_attachments`,
      args
    ) as Promise<PermissionsWithTokenRes>;
  },
  delete: (
    hierarchy: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string
  ) =>
    api.delete(
      `/api/${hierarchy}/${hierarchyId}/role_attachments/${subjectId}`
    ) as Promise<PermissionsRes>,
};

export const ALL_TIMEFRAMES = ["all", "year", "week"] as const;
export type TimeframeTuple = typeof ALL_TIMEFRAMES;
export type Timeframe = TimeframeTuple[number];

export type EnvEventArgs = {
  frame: Timeframe;
};

export type ModifyVolumeArgs = {
  diskSize: string;
};

export type ShareWorkspaceArgs = {
  addUsers: string[];
  organizationID: string;
};

export interface EnvEvent {
  Action: number;
  Email: string;
  Price: string;
  EnvName: string;
  EnvironmentId: string;
  Date: string;
  FormattedDate: string;
}

export interface EnvSaving {
  timeSaved: number;
  moneySaved: number;
}
export type EnvSavingsRes = {
  data?: EnvSaving;
} & AxiosRes &
  BaseRes;

export type IExecName = string;

export interface CreateWorkspaceReqBody {
  reposV1?: { [key: string]: IRepo };
  execsV1?: { [key: string]: IExec };
  name: string;
  description?: string;
  workspaceTemplateId: string;
  workspaceGroupId?: string;
  workspaceClassId?: WorkspaceClassId;
  version?: string;
  isStoppable?: boolean;
  networkId?: boolean;
  ideConfig?: IdeConfig;
  initBranch?: string;
  dotBrevPath?: string;
  instanceType?: string;
  diskStorage?: string;
  onContainer?: boolean;
  baseImage?: string;
  customContainer?: CustomContainer;
  vmOnlyMode?: boolean;
  portMappings?: Record<string, string> | null;
  files?: LaunchableFileRequest[] | null;
  labels?: Record<string, string> | null;
  verbYaml?: string;
  workspaceVersion?: "v1" | "v0";
  launchJupyterOnStart?: boolean;
  dockerCompose?: DockerCompose;
}

export interface UpdateWorkspaceReqBody {
  workspaceClassId?: string;
  instanceType?: string;
  isStoppable?: boolean;
  startupScriptPath?: string;
  name?: string;
  ideConfig?: IdeConfig;
  reposV1?: { [key: string]: IRepo };
  execsV1?: { [key: string]: IExec };
  addPort?: string;
  revokePort?: string;
}
export interface BuildVerbReqBody {
  verbYaml: string;
  idempotencyKey: string;
}

export interface AddFileAndBuildVerbContainerReqBody {
  fileObject: IFileObject;
  verbYaml: string;
  idempotencyKey?: string;
}

export interface ValidateFilePublicityReqBody {
  fileUrl: string;
  fileType: IFileType;
}

export interface ValidateDockerComposeReqBody {
  fileUrl: string;
}

export type ValidateDockerComposeRes = {
  data?: ValidateFile;
} & AxiosRes &
  BaseRes;

export type ValidateFilePublicityRes = {
  data?: ValidateFile;
} & AxiosRes &
  BaseRes;

interface ValidateFile {
  public: boolean;
  fileName?: string;
}

export interface ReadFileReqBody {
  filePath: string;
}

export interface CloneWorkspaceReqBody {
  name: string;
}

export type WorkspaceRes = {
  data?: IWorkspace;
} & AxiosRes &
  BaseRes;

export type WorkspacesRes = {
  data?: IWorkspace[];
} & AxiosRes &
  BaseRes;

export type BuildVerbRes = {
  data?: VerbBuild;
} & AxiosRes &
  BaseRes;

interface VerbBuild {
  logFilePath: string;
}

export type ReadFileRes = {
  data?: ReadFileContent;
} & AxiosRes &
  BaseRes;

interface ReadFileContent {
  content: string;
}

export type FileObjectRes = {
  data?: IFileObject[];
} & AxiosRes &
  BaseRes;

export type IFileObjectMap = { [filePath: string]: IFileObject };

export type FileMapRes = {
  data?: IFileObjectMap;
} & AxiosRes &
  BaseRes;

const Workspaces = {
  getFiles: (wsId: string) =>
    api.get(`/api/workspaces/${wsId}/files`) as Promise<FileObjectRes>,

  addFile: (wsId: string, file: IFileObject) =>
    api.post(`/api/workspaces/${wsId}/files`, {
      fileObject: file,
    }) as Promise<FileMapRes>,

  deleteFile: (wsId: string, filePath: string) =>
    api.delete(
      `/api/workspaces/${wsId}/files?path=${filePath}`
    ) as Promise<void>,

  getAllAsAdmin: () =>
    api.get(`/api/workspaces/admin`) as Promise<WorkspacesRes>,
  getAll: (orgId: string) =>
    api.get(`/api/organizations/${orgId}/workspaces`) as Promise<WorkspacesRes>,
  get: (wsId: string) =>
    api.get(`/api/workspaces/${wsId}`) as Promise<WorkspaceRes>,
  create: (orgId: string, args: CreateWorkspaceReqBody) =>
    api.post(
      `/api/organizations/${orgId}/workspaces`,
      args
    ) as Promise<WorkspaceRes>,
  update: (wsId: string, args: UpdateWorkspaceReqBody) =>
    api.put(`/api/workspaces/${wsId}`, args) as Promise<WorkspaceRes>,
  buildVerbContainerOnWorkspace: (wsId: string, args: BuildVerbReqBody) =>
    api.post(
      `/api/workspaces/${wsId}/buildverb`,
      args
    ) as Promise<BuildVerbRes>,

  addFileAndBuildVerb: (
    wsId: string,
    args: AddFileAndBuildVerbContainerReqBody
  ) =>
    api.post(
      `/api/workspaces/${wsId}/addfiles_and_buildverb`,
      args
    ) as Promise<BuildVerbRes>,

  validateFilePublicity: (args: ValidateFilePublicityReqBody) =>
    api.get(`/api/file-validation/check`, {
      params: {
        fileUrl: args.fileUrl,
        fileType: args.fileType,
      },
    }) as Promise<ValidateFilePublicityRes>,
  validateDockerCompose: (args: ValidateDockerComposeReqBody) =>
    api.post(`/api/file-validation/docker-compose`, args) as Promise<any>,

  readFileFromWorkspace: (wsId: string, args: ReadFileReqBody) =>
    api.post(`/api/workspaces/${wsId}/readfile`, args) as Promise<ReadFileRes>,
  delete: (wsId: string) =>
    api.delete(`/api/workspaces/${wsId}`) as Promise<WorkspaceRes>,
  stop: (wsId: string) =>
    api.put(`/api/workspaces/${wsId}/stop`) as Promise<WorkspaceRes>,
  start: (wsId: string) =>
    api.put(`/api/workspaces/${wsId}/start`) as Promise<WorkspaceRes>,
  reset: (wsId: string) =>
    api.put(`/api/workspaces/${wsId}/reset`) as Promise<WorkspaceRes>,
  hardReset: (wsId: string) =>
    api.put(`/api/workspaces/${wsId}/hardreset`) as Promise<WorkspaceRes>,
  clone: (wsId: string, args: CloneWorkspaceReqBody) =>
    api.post(`/api/workspaces/${wsId}/clone`, args) as Promise<WorkspaceRes>,
  fork: (wsId: string, args: CloneWorkspaceReqBody) =>
    api.post(`/api/workspaces/${wsId}/fork`, args) as Promise<WorkspaceRes>,
  envSavings: (wsId: string, args: EnvEventArgs) => {
    // add query params to url
    let url = `/api/workspaces/${wsId}/env_savings`;
    url = addQueryParams(url, args);

    return api.get(url) as Promise<EnvSavingsRes>;
  },
  modifyVolume: (wsId: string, args: ModifyVolumeArgs) =>
    api.post(
      `/api/workspaces/${wsId}/modify_volume`,
      args
    ) as Promise<WorkspaceRes>,
  shareWorkspace: (wsId: string, args: ShareWorkspaceArgs) =>
    api.post(
      `/api/workspaces/${wsId}/share_workspace`,
      args
    ) as Promise<WorkspaceRes>,
};

function addQueryParams(url: string, args: any) {
  const params = new URLSearchParams();
  Object.keys(args).forEach((key) => {
    params.append(key, args[key]);
  });
  return `${url}?${params.toString()}`;
}

const Instances = {
  get: (wsGroupId: string, region?: string) => {
    if (region) {
      return api.get(
        `api/instances/types/${wsGroupId}?region=${region}`
      ) as Promise<any>;
    }
    return api.get(
      `api/instances/types/${wsGroupId}?region=us-west-2`
    ) as Promise<any>;
  },
  getAllInstanceTypesAvailable: (orgId: string, imageID?: string) => {
    if (imageID) {
      return api.get(
        `api/instances/alltypesavailable/${orgId}?imageid=${imageID}`
      ) as Promise<any>;
    }
    return api.get(`api/instances/alltypesavailable/${orgId}`) as Promise<any>;
  },
  getInstanceByType: (instanceType: string, workspaceGroupID: string) => {
    return api.get(
      `api/public-instances/types?instanceType=${instanceType}&workspaceGroupID=${workspaceGroupID}`
    ) as Promise<any>;
  },
};

type GetSecretsParams = {
  hierarchyType: HierarchyType;
  hierarchyId: string;
} & BaseParams;

export type CreateSecretReqBody = CreateSecretReq;

export interface SecretReqSrc {
  type: SrcType;
  config: SrcConfig;
}

export interface SecretReqDest {
  type: DestType;
  config: DestConfig;
}
export interface UpdateSecretReqBody {
  id: string;
  name?: string;
  src?: SecretReqSrc;
  dest?: SecretReqDest;
}

type SecretsRes = {
  data?: { secret: ISecret; value: string }[];
} & AxiosRes &
  BaseRes;

type SecretRes = {
  data?: ISecret;
} & AxiosRes &
  BaseRes;

const Secrets = {
  get: (hierarchyType: HierarchyType, hierarchyId: string) => {
    const params: GetSecretsParams = { hierarchyType, hierarchyId };
    return api.get("/api/secrets", { params }) as Promise<SecretsRes>;
  },
  create: (args: CreateSecretReqBody) =>
    api.post("/api/secrets", args) as Promise<SecretRes>,
  update: (args: UpdateSecretReqBody) =>
    api.put("/api/secrets", args) as Promise<SecretRes>,
  delete: (secretId: string) =>
    api.delete(`/api/secrets/${secretId}`) as Promise<SecretRes>,
};

interface RenderRequest {
  sourceVal: SourceVal;
}

interface SourceVal {
  url: string;
  type: string;
}

export interface GithubRepoReqBody {
  repoUrl: string;
}

export type GithubRepoRes = {
  data?: GithubRepo;
} & AxiosRes &
  BaseRes;

interface GithubRepo {
  stars: number;
  forks: number;
  watchers: number;
}

const Github = {
  repoInfo: (args: GithubRepoReqBody) =>
    api.get("/api/github/info", {
      params: {
        repoUrl: args.repoUrl,
      },
    }) as Promise<GithubRepoRes>,
};

const Notebooks = {
  preview: (request: RenderRequest) =>
    api.post("/api/notebooks/render", request),
};

export type BillingClientCreditInfoRes = {
  data?: {
    last4?: string;
    brand?: string;
    expiration?: string;
  };
} & AxiosRes &
  BaseRes;

export type BillingClientSecretRes = {
  data?: {
    clientSecret: string;
    last4?: string;
    brand?: string;
    expiration?: string;
  };
} & AxiosRes &
  BaseRes;
export interface InvoiceReqBody {
  stripeId: string;
  descriptionMatcher: string;
  orgId: string;
}

export type AdminOrgWithPM = {
  Org: AdminIOrganization;
  Email: string;
  TotalUsage: string;
  Workspaces: IWorkspace[];
};

export type AdminOrgsWithPMRes = {
  data?: AdminOrgWithPM[];
} & AxiosRes &
  BaseRes;

export type ResourceLims = {
  maxCostUtilizationPerHour: string;
  totalCostOfWorkspaces: string;
};

export type ResourceLimitsRes = {
  data: ResourceLims[];
} & AxiosRes &
  BaseRes;

export type PurchaseGiftCardReq = {
  amount: string;
  recipientName: string;
  recipientEmail: string;
  senderEmail: string;
  senderName: string;
  paymentMethod: string;
  message: string;
  description: string;
  hours: string;
};

export type PurchaseGiftCardRes = {
  shortCode: string;
  giftCardId: string;
} & AxiosRes &
  BaseRes;

const Billing = {
  getPaymentMethods: (orgId: string) =>
    api.get(
      `/api/organizations/${orgId}/billing/paymentMethods`
    ) as Promise<any>,
  getClientSecret: (orgID: string) =>
    api.get(
      `/api/organizations/${orgID}/billing/setup`
    ) as Promise<BillingClientSecretRes>,
  getSavedCreditInfo: (orgID: string) =>
    api.get(
      `/api/organizations/${orgID}/billing`
    ) as Promise<BillingClientCreditInfoRes>,
  retryHold: (orgID: string, paymentMethodId: string) =>
    api.post(`/api/organizations/${orgID}/billing/hold/retry`, {
      paymentMethodId,
    }) as Promise<any>,
  purchaseGiftCard: (purchaseGiftCardReq: PurchaseGiftCardReq) => {
    return api.post(
      `/api/gift-card/purchase`,
      purchaseGiftCardReq
    ) as Promise<PurchaseGiftCardRes>;
  },
};

const Launchables = {
  getLaunchableMetrics: (orgId: string) =>
    api.get(
      `/api/organizations/${orgId}/launchable-analytics`
    ) as Promise<LaunchableAnalyticsRes>,
  getLaunchableDailyMetrics: (orgId: string) =>
    api.get(
      `/api/organizations/${orgId}/launchable-analytics/daily`
    ) as Promise<LaunchableDailyAnalyticsRes>,
  listLaunchables: (orgId: string) =>
    api.get(
      `/api/organizations/${orgId}/launchables`
    ) as Promise<ListLaunchableRes>,
  getLaunchable: (launchableId: string) =>
    api.get(`/api/launchables/${launchableId}`) as Promise<GetLaunchableRes>,

  createLaunchable: (orgId: string, name: string, rawURL: string) =>
    api.post(`/api/organizations/${orgId}/launchables`, {
      name,
      rawURL,
    }) as Promise<CreateLaunchableRes>,
  createEnvironmentLaunchable: (
    orgId: string,
    createLaunchableRequest: CreateLaunchableRequest
  ) =>
    api.post(
      `/api/organizations/${orgId}/v2/launchables`,
      createLaunchableRequest
    ) as Promise<CreateLaunchableRes>,
  deleteLaunchable: (orgId: string, launchableId: string) =>
    api.delete(
      `/api/organizations/${orgId}/launchables/${launchableId}`
    ) as Promise<AxiosRes & BaseRes>,

  getLaunchablePoints: (orgId: string) =>
    api.get(
      `/api/organizations/${orgId}/launchable-points`
    ) as Promise<GetLaunchablePointsRes>,

  redeemLaunchablePoints: (orgId: string) =>
    api.post(
      `/api/organizations/${orgId}/redeem-cloud-points`,
      {}
    ) as Promise<GetLaunchablePointsRes>,

  editLaunchable: (orgId: string, envId: string, newRawURL: string) =>
    api.post(`/api/organizations/${orgId}/launchables/edit`, {
      envId,
      newRawURL,
    }) as Promise<EditLaunchableRes>,

  editEnvironmentLaunchable: (
    orgId: string,
    modifyEnvironmentRequest: ModifyLaunchableRequest
  ) =>
    api.post(
      `/api/organizations/${orgId}/v2/launchables/edit`,
      modifyEnvironmentRequest
    ) as Promise<EditLaunchableRes>,
};

export type GetApiKeyRequest = {
  orgId: string;
};

export type ApiKey = {
  name: string;
  value: string;
};

export type GetApiKeyRes = {
  data: ApiKey[];
} & AxiosRes &
  BaseRes;

const InferenceEndpoints = {
  getApiKey: (args: GetApiKeyRequest) =>
    api.get(
      `/api/organizations/${args.orgId}/apikeys`
    ) as Promise<GetApiKeyRes>,
};

export type RedeemLaunchablePointsRes = {
  data: {
    Amount: number;
  } & AxiosRes &
    BaseRes;
};

export type EditLaunchableRes = {
  data: string;
} & AxiosRes &
  BaseRes;

export type LaunchableDailyAnalyticsRes = {
  data: LaunchableDailyAnalytics[];
} & AxiosRes &
  BaseRes;

export type LaunchableDailyAnalytics = {
  date: string;
  launchables: LaunchableAnalyticsForDate[];
};

export type LaunchableAnalyticsForDate = {
  id: string;
  views: number;
  deployments: number;
};

export type LaunchableCloudPointsGenerated = {
  LaunchableCloudPointsGenerated: LaunchableCloudPoints[];
};

export type LaunchableCloudPoints = {
  LaunchableID: string;
  AmountGenerated: number;
};

export type GetLaunchablePointsRes = {
  data: LaunchableCloudPointsGenerated;
} & AxiosRes &
  BaseRes;

export type CreateLaunchableRes = {
  data: {
    id: string;
  };
} & AxiosRes &
  BaseRes;

export type LaunchableAnalytics = {
  views: number;
  deployments: number;
};

export type LaunchableFromList = {
  id: string;
  name: string;
  rawURL?: string;
  createdByUserId?: string;
  createdByOrgId?: string;
  createWorkspaceRequest?: LaunchableCreateWorkspaceRequest;
  buildRequest?: LaunchableBuildRequest;
  file?: LaunchableFileRequest;
  couponCode?: string;
};

export type CreateLaunchableRequest = {
  name: string;
  createWorkspaceRequest: LaunchableCreateWorkspaceRequest;
  buildRequest: LaunchableBuildRequest;
  file?: LaunchableFileRequest | null;
};

export type ModifyLaunchableRequest = {
  id: string;
  createWorkspaceRequest: LaunchableCreateWorkspaceRequest;
  buildRequest: LaunchableBuildRequest;
  file?: LaunchableFileRequest | null;
};

export type LaunchableFileRequest = {
  url: string;
  path: string;
};

export type LaunchableCreateWorkspaceRequest = {
  instanceType: string;
  workspaceGroupId: string;
  storage?: string;
  region?: string;
};

export interface LaunchableBuildRequest {
  verbBuild?: LaunchableVerbBuild;
  containerBuild?: CustomContainer;
  vmBuild?: VMBuild;
  dockerCompose?: DockerCompose;
  ports: LaunchablePort[];
}

export interface VMBuild {
  forceJupyterInstall: boolean;
}

export interface LaunchablePort {
  name: string;
  port: string;
}

interface LaunchableVerbBuild {
  verbYaml: string;
}

export type LaunchableFromAnalytics = {
  id: string;
  organization_id: string;
  name: string;
  analytics: LaunchableAnalytics;
};

export type LaunchableAnalyticsRes = {
  data: {
    Launchables: LaunchableFromAnalytics[];
  };
} & AxiosRes &
  BaseRes;

export type ListLaunchableRes = {
  data: LaunchableFromList[];
} & AxiosRes &
  BaseRes;

export type GetLaunchableRes = {
  data: LaunchableFromList;
} & AxiosRes &
  BaseRes;

export type AddCreditsToOrganizationReq = {
  amountUSD: string;
  organizationId: string;
};

export type SearchReq = {
  query: string;
};

export type SearchResponseStruct = {
  users: AdminUserType[];
  organizations: AdminOrganizationType[];
  workspaces: IWorkspace[];
  errors: string[];
};

export type SearchRes = {
  data: SearchResponseStruct;
} & AxiosRes &
  BaseRes;

export type UserAsAdminRes = {
  data: AdminUserType;
} & AxiosRes &
  BaseRes;

export type UserOrganizationsAsAdminRes = {
  data: AdminOrganizationType[];
} & AxiosRes &
  BaseRes;

export type OrgAsAdminRes = {
  data: AdminOrganizationType;
} & AxiosRes &
  BaseRes;

export type AddCreditsToOrganizationRes = {
  data: any;
} & AxiosRes &
  BaseRes;

const Admin = {
  getOrgsWithPaymentMethods: () =>
    api.get(
      "/api/admins/orgs-with-payment-methods"
    ) as Promise<AdminOrgsWithPMRes>,
  sendInvoice: (args: InvoiceReqBody) =>
    api.post(`/api/admins/send-invoice`, args) as Promise<any>,

  blockUser: (userId: string) =>
    api.put(`/api/users/${userId}/block`) as Promise<UserRes>,

  unblockUser: (userId: string) =>
    api.put(`/api/users/${userId}/block`, {
      isBlocked: false,
    }) as Promise<UserRes>,
  addCreditsToOrganization: (args: AddCreditsToOrganizationReq) =>
    api.post(`/api/admins/add-credits-to-org`, args) as Promise<any>,
  search: (searchReq: SearchReq) =>
    api.get(`/api/admins/search`, { params: searchReq }) as Promise<SearchRes>,
  getUserAsAdmin: (userId: string) =>
    api.get(`/api/admins/user/${userId}`) as Promise<UserAsAdminRes>,
  getUserOrganizations: (userId: string) =>
    api.get(
      `/api/admins/user/${userId}/orgs`
    ) as Promise<UserOrganizationsAsAdminRes>,
  getOrganizationAsAdmin: (orgId: string) =>
    api.get(`/api/admins/org/${orgId}`) as Promise<OrgAsAdminRes>,
  addDgxcToOrganization: (orgId: string) =>
    api.post(`/api/admins/org/${orgId}/add-dgxc`) as Promise<
      AxiosRes & BaseRes
    >,
  setResourceLimits: (orgId: string, maxResources: number) =>
    api.post(`/api/admins/org/${orgId}/set-resource-limits`, {
      maxResources: maxResources, // in cents
    }) as Promise<AxiosRes & BaseRes>,
};

const ResourceLimits = {
  getResourceLimits: (orgId: string, userId: string) =>
    api.get(
      `/api/resourcelimits?orgId=${orgId || ""}&userId=${userId || ""}`
    ) as Promise<ResourceLimitsRes>,
};

export enum DeploymentModelProvider {
  UNSPECIFIED = 0,
  HUGGING_FACE = 1,
}

export function getProviderString(provider: DeploymentModelProvider): string {
  switch (provider) {
    case DeploymentModelProvider.UNSPECIFIED:
      return "UNSPECIFIED";
    case DeploymentModelProvider.HUGGING_FACE:
      return "HUGGING_FACE";
    default:
      return "UNKNOWN";
  }
}

interface DeploymentModel {
  model_name?: string;
  model_provider?: DeploymentModelProvider;
  model_id: string;
}
interface DeploymentRegistry {
  username?: string;
  password?: string;
  url?: string;
}

interface DeploymentContainer {
  base_image?: string;
  entrypoint?: string[];
  registry?: DeploymentRegistry;
}

interface DeploymentArtifact {
  model?: DeploymentModel;
  container?: DeploymentContainer;
}

interface CreateDeploymentRequest {
  name?: string;
  namespace?: string;
  deployment_provider_cred_id?: string;
  instance_type?: string;
  location?: string;
  min_instances?: number;
  max_instances?: number;
  max_concurrency?: number;
  artifact?: DeploymentArtifact;
  labels?: { [key: string]: string };
}

export enum DeploymentStatus {
  UNSPECIFIED = 0,
  CREATED = 1,
  RUNNING = 2,
  DELETING = 3,
  DELETED = 4,
  DEPLOYING = 5,
  ERROR = 6,
}

export interface Deployment {
  deployment_id?: string;
  create_time?: Timestamp;
  update_time?: Timestamp;
  name?: string;
  namespace?: string;
  deployment_provider_cred_id?: string;
  instance_type?: string;
  location?: string;
  providerDeploymentId?: string;
  min_instances?: number;
  max_instances?: number;
  maxConcurrency?: number;
  artifact?: DeploymentArtifact;
  model_id?: string;
  publicDns?: string;
  status?: DeploymentStatus;
  retireTime?: Timestamp;
  labels?: { [key: string]: string };
}

enum DeploymentProvider {
  DEPLOYMENT_PROVIDER_UNSPECIFIED = 0,
  DEPLOYMENT_PROVIDER_NVCF = 1,
}

export interface Gpu {
  count?: number;
  memory?: string;
  manufacturer?: string;
  name?: string;
  networkDetails?: string;
  memoryDetails?: string;
}

export interface DeploymentInstanceType {
  type?: string;
  location?: string;
  supported_gpus?: Gpu[];
  preemptible?: boolean;
  is_available?: boolean;
  deployment_provider_cred_id?: string;
  deployment_provider?: DeploymentProvider;
}

export interface DeploymentLog {
  timestamp?: string;
  message?: string;
}

export interface MetricDataPointInt {
  // Define the structure of MetricDataPointInt
  // This is a placeholder and should be replaced with the actual structure
  time: Timestamp;
  value: number;
}

interface MetricDataPointFloat {
  // Define the structure of MetricDataPointFloat
  // This is a placeholder and should be replaced with the actual structure
  time: Timestamp;
  value: number;
}

interface Timestamp {
  /**
   * Represents seconds of UTC time since Unix epoch
   * 1970-01-01T00:00:00Z. Must be from 0001-01-01T00:00:00Z to
   * 9999-12-31T23:59:59Z inclusive.
   */
  seconds?: number;
  /**
   * Non-negative fractions of a second at nanosecond resolution. Negative
   * second values with fractions must still have non-negative nanos values
   * that count forward in time. Must be from 0 to 999,999,999
   * inclusive.
   */
  nanos?: number;
}

export interface DeploymentMetrics {
  total_invocations?: number;
  average_inference_time?: number;
  total_active_instances?: number;
  total_invocation_failures?: number;
  invocation_per_sec_time_series?: MetricDataPointInt[];
  queue_depth_time_series?: MetricDataPointInt[];
  avg_inference_time_series?: MetricDataPointFloat[];
  instances_time_series?: MetricDataPointInt[];
  invocation_success_rate_time_series?: MetricDataPointFloat[];
}

export type DeploymentRes = {
  data: Deployment;
} & AxiosRes &
  BaseRes;

export type DeploymentLogsRes = {
  data: DeploymentLog[];
} & AxiosRes &
  BaseRes;

export type DeploymentMetricRes = {
  data: DeploymentMetrics;
} & AxiosRes &
  BaseRes;

export type DeploymentsRes = {
  data: Deployment[];
} & AxiosRes &
  BaseRes;

export type DeploymentInstanceTypeRes = {
  data: DeploymentInstanceType[];
} & AxiosRes &
  BaseRes;

const Deployments = {
  createDeployments: (
    orgId: string,
    createDeploymentReq: CreateDeploymentRequest
  ) =>
    api.post(
      `/api/organizations/${orgId}/deployments`,
      createDeploymentReq
    ) as Promise<DeploymentRes>,
  getDeployment: (orgId: string, deploymentId: string) =>
    api.get(
      `/api/organizations/${orgId}/deployments/${deploymentId}`
    ) as Promise<DeploymentRes>,
  getDeploymentLogs: (orgId: string, deploymentId: string) =>
    api.get(
      `/api/organizations/${orgId}/deployments/${deploymentId}/logs`
    ) as Promise<DeploymentLogsRes>,
  getDeploymentMetrics: (orgId: string, deploymentId: string) =>
    api.get(
      `/api/organizations/${orgId}/deployments/${deploymentId}/metrics`
    ) as Promise<DeploymentMetricRes>,
  getDeployments: (orgId: string) =>
    api.get(
      `/api/organizations/${orgId}/deployments`
    ) as Promise<DeploymentsRes>,
  deleteDeployment: (orgId: string, deploymentId: string) =>
    api.delete(
      `/api/organizations/${orgId}/deployments/${deploymentId}`
    ) as Promise<AxiosRes & BaseRes>,
  getInstanceTypes: (orgId: string) =>
    api.get(
      `/api/organizations/${orgId}/deployments/instancetypes`
    ) as Promise<DeploymentInstanceTypeRes>,
};

const Models = {
  getModels: (orgId: string) =>
    api.get(`/api/organizations/${orgId}/models`) as Promise<ModelsRes>,
};

export interface ModelGpu {
  count: number;
  memory: string;
  name: string;
}

export interface ModelHardwareRequirement {
  min_vram_gb: string;
  supported_gpus: ModelGpu[];
}

interface ModelRegistry {
  // Empty for now based on the example, but could contain properties if needed
}

interface ModelContainer {
  base_image: string;
  command_args: string[];
  registry: ModelRegistry;
}

interface ModelInferenceServer {
  inference_url_path: string;
  inference_port: number;
  health_url_path: string;
}

export interface Model {
  model_id: string;
  create_time: Timestamp;
  update_time: Timestamp;
  model_name: string;
  container: ModelContainer;
  inference_server: ModelInferenceServer;
  hardware_requirements: ModelHardwareRequirement[];
  labels: { [key: string]: string };
}

export type ModelsRes = {
  data: Model[];
} & AxiosRes &
  BaseRes;

export default {
  Users,
  WorkspaceTemplate,
  Roles,
  Workspaces,
  Permissions,
  Organizations,
  Secrets,
  Billing,
  Brevent,
  Instances,
  Admin,
  Applications,
  ResourceLimits,
  BillingCredits,
  Notebooks,
  Launchables,
  Deployments,
  Models,
  Github,
  InferenceEndpoints,
};
