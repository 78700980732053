/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Button from "components/UI-lib/Button";

import { OrgContext } from "contexts/OrgContext";
import { UserContext } from "contexts/UserContext";
import { PermissionHierarchyType } from "models/Permission.model";
import { displayPermissionHierarchyType } from "components/utils";
import { PermissionsContext } from "contexts/PermissionsContext";
import AccessCard from "components/AccessPanel/AccessCard";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { ReactMultiEmail } from "react-multi-email";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import TeamInvite from "./TeamInvite";
import TeamList from "./TeamList";

const OrgPagePeople: React.FC = (props) => {
  console.log("Route: /org/:orgId/team");

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
              Team
            </h1>
            <div className="flex">
              <p className="mt-2 text-sm text-gray-700 dark:text-secondary">
                Manage your team members and their permissions
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 flex flex-col">
          <div className="-my-2 -mx-4 overflow-y-visible sm:-mx-6 lg:-mx-8">
            <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="flex flex-row justify-center items-center mb-5">
                <TeamInvite />
              </div>
              <div>
                <TeamList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgPagePeople;
